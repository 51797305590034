import React, { useMemo, useCallback, useState } from "react";
import { useParams } from "@reach/router";
import { useDispatch } from "react-redux";

import USER_ROLE from "../../enums/user-role.json";
import MUNICIPALITY from "../../enums/municipality.json";

import { updateUserRoles } from "../api/roles";
import { updateUserRoles as updateUserRolesAction } from "../state/user-roles/user-roles-actions";
import { useTranslations } from "../hooks/translations";
import SEO from "../components/seo";
import SpinnerOverlay from "../components/spinner-overlay";
import Form from "../components/form";
import Dropdown from "../components/dropdown";
import Button from "../components/button";
import StatusModal from "../components/status-modal";

import { useUser } from "./hooks/users";
import { useUserRoles } from "./hooks/user-roles";

import styles from "./volunteer-roles.module.scss";

const VolunteerRoles = () => {
  const t = useTranslations("clientRoutes.volunteerRoles");

  const enums = useTranslations("enums");

  const { userId } = useParams();

  const { loading: loadingUser, user } = useUser(userId);

  const { loading: loadingRoles, roles, municipalities } = useUserRoles(userId);

  const municipalityDropdownValues = useMemo(
    () =>
      Object.keys(MUNICIPALITY).map(key => ({
        key,
        value: enums.municipality[key]
      })),
    []
  );

  const rolesDropdownValues = useMemo(
    () =>
      Object.keys(USER_ROLE).map(key => ({
        key,
        value: enums.userRole[key]
      })),
    []
  );

  const formFields = useMemo(
    () => ({
      roles: {
        value: roles || []
      },
      municipalities: {
        value: municipalities || []
      }
    }),
    [roles, municipalities]
  );

  const [submitting, setSubmitting] = useState(false);

  const [response, setResponse] = useState(null);

  const dispatch = useDispatch();

  const submitChanges = useCallback(
    formValue => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      updateUserRoles(userId, formValue)
        .then(
          () => {
            dispatch(updateUserRolesAction(userId, formValue));
            setResponse({
              messages: t.success
            });
          },
          () => {
            setResponse({
              error: true,
              messages: t.error
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [userId, submitting, setSubmitting, setResponse]
  );

  const seoTitle = useMemo(
    () => (user ? `${user.name} ${user.surname} - ${t.heading}` : t.heading),
    [user]
  );

  return (
    <>
      <SEO title={seoTitle} robots="noindex, nofollow" />

      <section>
        <div className="bp-container">
          <h1>{t.heading}</h1>

          {loadingUser || loadingRoles || !user || !roles ? (
            <SpinnerOverlay />
          ) : (
            <div className={`bp-card ${styles.card}`}>
              <div className={`bp-between ${styles.header}`}>
                <div className="bp-between">
                  <h2>
                    {user.name} {user.surname}
                  </h2>
                </div>
              </div>

              <Form fields={formFields} onSubmit={submitChanges} className={styles.form}>
                {({ setFieldValue, fields }) => (
                  <>
                    <Dropdown
                      id="roles"
                      label={t.volunteerHasRoles}
                      options={rolesDropdownValues}
                      field={fields.roles}
                      onChange={setFieldValue}
                      multiple
                    />

                    <Dropdown
                      id="municipalities"
                      label={t.municipalities}
                      options={municipalityDropdownValues}
                      field={fields.municipalities}
                      onChange={setFieldValue}
                      multiple
                    />

                    <Button
                      type="submit"
                      className={`bp-standard bp-submit-button ${styles.save}`}
                      busy={submitting}
                    >
                      {t.save}
                    </Button>
                  </>
                )}
              </Form>
            </div>
          )}
        </div>
      </section>

      {response ? (
        <StatusModal
          error={response.error}
          heading={response.messages.heading}
          description={response.messages.description}
          onClose={() => setResponse(null)}
        />
      ) : null}
    </>
  );
};

export default VolunteerRoles;
