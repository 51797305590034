// This encodes data according to https://tools.ietf.org/html/rfc4180
// The first line of the data should contain the headers

import { reportError } from "./error-handler";
import { getLocalizedDateString } from "./time";

const convertValueToString = value =>
  value === null || value === undefined ? `""` : `"${value.toString().replace(/"/g, '""')}"`;

const convertArrayToCSV = array => `${array.join("\t")}\r\n`;

const generateHeader = headers => convertArrayToCSV(headers.map(convertValueToString));

const encodeLine = (entry, fields) =>
  convertArrayToCSV(fields.map(field => convertValueToString(entry[field])));

const downloadCSV = (data, filename = "data") => {
  const blob = new Blob([data], { type: "text/csv" });
  const anchor = document.createElement("a");
  anchor.href = URL.createObjectURL(blob);
  anchor.download = `${filename}-${getLocalizedDateString()}.csv`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const copyCSVToClipboard = data => {
  const textarea = document.createElement("textarea");
  textarea.textContent = data;
  // This prevents scrolling to bottom of page in Microsoft Edge
  textarea.style.position = "fixed";
  document.body.appendChild(textarea);
  textarea.select();

  try {
    // A security exception might be thrown by some browsers
    return document.execCommand("copy");
  } catch (err) {
    console.warn("Failed to copy csv to clipboard", err);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};

export const generateCSV = (data, { headers, fields, filename }) =>
  new Promise((resolve, reject) => {
    // The timeout allows me to render a loading indicator
    setTimeout(() => {
      let csv;
      try {
        csv = generateHeader(headers);
        data.forEach(entry => {
          csv += encodeLine(entry, fields);
        });
      } catch (err) {
        reportError(err, "CSV generation problem");
        return reject(err);
      }

      const copySuccessful = copyCSVToClipboard(csv);

      if (!copySuccessful) {
        try {
          downloadCSV(csv, filename);
        } catch (err) {
          reportError(err, "Failed to download CSV");
          return reject(err);
        }
      }

      return resolve();
    });
  });
