import React, { useMemo } from "react";
import PropTypes from "prop-types";

import YES_NO from "../../enums/yes-no.json";

import WizardStepSVG from "../assets/icons/wizard-step.svg";

import { useTranslations } from "../hooks/translations";
import { useSelf } from "../hooks/self";
import SEO from "../components/seo";

import Underage from "./my-status/underage";
import AdditionalInfo from "./my-status/additional-info";
import SecondPhaseConfirmation from "./my-status/second-phase-confirmation";
import SecondPhaseNo from "./my-status/second-phase-no";
import SecondPhaseYes from "./my-status/second-phase-yes";
import Training from "./my-status/training";
import Licence from "./my-status/licence";
import Ready from "./my-status/ready";

import styles from "./my-status.module.scss";

const WIZARD_STEPS = [
  {
    key: "adult",
    Component: Underage,
    isComplete: user => user.adult === YES_NO.YES
  },
  {
    key: "additional",
    Component: AdditionalInfo,
    isComplete: user => user.additionalInfoAvailable === YES_NO.YES,
    wizard: true
  },
  {
    key: "second-phase",
    Component: SecondPhaseConfirmation,
    isComplete: user => user.secondPhaseOnly || user.secondPhaseConfirmed
  },
  {
    key: "second-phase-no",
    Component: SecondPhaseNo,
    isComplete: user => user.secondPhaseOnly || user.secondPhase === YES_NO.YES
  },
  {
    key: "second-phase-yes",
    Component: SecondPhaseYes,
    isComplete: user => user.secondPhaseOnly || user.scheduleRevised
  },
  {
    key: "training",
    Component: Training,
    isComplete: user => user.trainingCompleted === YES_NO.YES,
    wizard: true
  },
  {
    key: "licence",
    Component: Licence,
    isComplete: user => user.hasLicence === YES_NO.YES,
    wizard: true
  },
  {
    key: "complete",
    Component: Ready,
    isComplete: () => false
  }
];

const WizardStep = ({ step, stepIndex }) => (
  <div
    className={`bp-center ${styles.wizardStep} ${step.complete ? styles.complete : null} ${
      step.current ? styles.current : null
    }`}
  >
    <WizardStepSVG className="bp-fill" />
    <span>
      {stepIndex + 1}
      .&nbsp;
      {step.name}
    </span>
  </div>
);

WizardStep.propTypes = {
  step: PropTypes.shape({
    complete: PropTypes.bool,
    current: PropTypes.bool,
    name: PropTypes.string.isRequired
  }).isRequired,
  stepIndex: PropTypes.number.isRequired
};

const MyStatus = () => {
  const t = useTranslations("clientRoutes.myStatus");

  const { userData } = useSelf();

  const steps = useMemo(() => {
    const stepsCopy = WIZARD_STEPS.map(step => ({
      ...step,
      name: t[step.key],
      complete: step.isComplete(userData)
    }));

    stepsCopy.find(({ complete }) => !complete).current = true;

    return stepsCopy;
  }, [userData]);

  const currentStep = useMemo(() => steps.find(({ current }) => current), [steps]);

  const wizardSteps = useMemo(() => steps.filter(({ wizard }) => wizard), [steps]);

  const wizardStepsRemaining = useMemo(
    () => wizardSteps.filter(({ complete }) => !complete).length,
    [wizardSteps]
  );

  return (
    <>
      <SEO title={t.title} robots="noindex, nofollow" />

      <section className={styles.section}>
        <div className="bp-container bp-left">
          {currentStep.wizard ? (
            <>
              <div className={styles.header}>
                {t.heading} {wizardStepsRemaining} {wizardStepsRemaining > 1 ? t.steps : t.step}!
              </div>

              <div className={styles.statusBar}>
                {wizardSteps.map((step, stepIndex) => (
                  <WizardStep key={step.key} step={step} stepIndex={stepIndex} />
                ))}
              </div>
            </>
          ) : null}

          <div className={`${styles.step} ${currentStep.wizard ? styles.wizardComponent : ""}`}>
            <currentStep.Component />
          </div>
        </div>
      </section>
    </>
  );
};

export default MyStatus;
