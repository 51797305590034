import React, { useState } from "react";

import { useSelf } from "../hooks/self";
import SEO from "../components/seo";
import Button from "../components/button";

import PreliminaryScheduleForm from "./schedules/preliminary-schedule-form";
import SchedulesComponent from "./schedules/schedules";
import ScheduleInstructionsModal from "./schedules/instructions";

import styles from "./schedules.module.scss";

const Schedules = () => {
  const { userData } = useSelf();

  const [instructionsVisible, setInstructionsVisible] = useState(false);

  return (
    <>
      <section>
        <SEO title="Grafikai" robots="noindex, nofollow" />

        <div className="bp-container bp-left">
          <div
            className={`bp-between ${styles.header} ${
              userData.scheduleRevised ? "" : styles.center
            }`}
          >
            <h1 className={styles.heading}>Stebėjimo grafikai</h1>

            {userData.scheduleRevised ? (
              <Button onClick={() => setInstructionsVisible(true)} className="bp-standard">
                Instrukcija ir svarbi informacija!
              </Button>
            ) : null}
          </div>

          {userData.scheduleRevised ? (
            <SchedulesComponent />
          ) : (
            <>
              <p className="bp-center">
                Pažymėk savo apytikrius rinkimų savaitės planus žemiau esančioje formoje ir sistema
                Tau pateiks stebėjimo grafikus.
              </p>

              <PreliminaryScheduleForm />
            </>
          )}
        </div>
      </section>

      {instructionsVisible ? (
        <ScheduleInstructionsModal onClose={() => setInstructionsVisible(false)} />
      ) : null}
    </>
  );
};

export default Schedules;
