import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { useLinkTranslation } from "../hooks/link-translation";
import { useTranslations } from "../hooks/translations";
import { useSelf } from "../hooks/self";

import SEO from "./seo";
import Table from "./table";
import SpinnerOverlay from "./spinner-overlay";
import Button from "./button";
import TableDropdownFilter from "./table-dropdown-filter";

import styles from "./table-page.module.scss";

const TablePage = ({
  id,
  columns: columnConfig,
  hiddenColumns,
  loading,
  data,
  path,
  title,
  heading,
  filename,
  headerTranslations,
  transformCSVRow,
  className
}) => {
  const t = useTranslations("table");

  const enums = useTranslations("enums");

  const self = useSelf();

  const columns = useMemo(
    () =>
      columnConfig.map(({ key, filter, accessor, allowGlobalFilter, ...props }) => ({
        id: key,
        Header: headerTranslations[key],
        Filter: filter
          ? ({ column: { filterValue, setFilter } }) => {
              let { options } = filter;

              if (typeof options === "function") {
                options = options({ claims: self.claims, enums });
              }

              if (Array.isArray(options) && filter.translationKey) {
                options = options.map(option => enums[filter.translationKey][option]);
              }

              const FilterComponent = filter.component || TableDropdownFilter;

              return (
                <FilterComponent
                  id={key}
                  label={headerTranslations[key]}
                  value={filterValue}
                  onChange={setFilter}
                  options={options}
                  multiple={filter.multiple}
                />
              );
            }
          : null,
        filter: filter ? filter.comparator : null,
        initialFilterValue:
          filter &&
          (filter.initialValue && filter.translationKey
            ? enums[filter.translationKey][filter.initialValue]
            : filter.initialValue),
        headerName: headerTranslations[key],
        accessor,
        accessorKey: accessor,
        disableGlobalFilter: !allowGlobalFilter,
        filterColumns: filter ? filter.columns : null,
        ...props
      })),
    []
  );

  const pagePath = useLinkTranslation(path);

  const onRowClick = useCallback(row => navigate(`${pagePath}/${row.id}`), [pagePath]);

  const tableRef = useRef();

  const copyData = useCallback(() => {
    tableRef.current.generateCSV({
      filename,
      success: t.copySuccess,
      error: t.copyError,
      transformRow: transformCSVRow
    });
  }, [tableRef, transformCSVRow]);

  return (
    <>
      <SEO title={title} robots="noindex, nofollow" />

      <section>
        <div className="bp-container">
          <div className={`bp-between ${styles.heading}`}>
            <h1>{heading}</h1>

            <Button className="bp-standard" onClick={copyData}>
              {t.copy}
            </Button>
          </div>

          {loading ? (
            <SpinnerOverlay />
          ) : (
            <Table
              id={id}
              columns={columns}
              data={data}
              onRowClick={onRowClick}
              hiddenColumns={hiddenColumns}
              className={className}
              ref={tableRef}
            />
          )}
        </div>
      </section>
    </>
  );
};

TablePage.propTypes = {
  id: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      filter: PropTypes.shape({
        options: PropTypes.any,
        translationKey: PropTypes.string,
        multiple: PropTypes.bool,
        comparator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        initialValue: PropTypes.any,
        columns: PropTypes.number
      }),
      accessor: PropTypes.string.isRequired,
      allowGlobalFilter: PropTypes.bool
    }).isRequired
  ).isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  headerTranslations: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string.isRequired),
  transformCSVRow: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string
};

TablePage.defaultProps = {
  loading: true,
  hiddenColumns: undefined,
  transformCSVRow: undefined,
  data: [],
  className: ""
};

export default TablePage;
