import React from "react";

import PreliminaryScheduleForm from "../schedules/preliminary-schedule-form";

const SecondPhaseYes = () => {
  return (
    <>
      <h1 className="bp-heading">Keliaujam į kitus rinkimus!</h1>

      <div className="bp-container bp-left">
        <p className="bp-center">
          Labai prašome pateikti savo rinkimų savaitės planą žemiau esančioje formoje. Tai padės
          mums planuoti stebėtojų resursus.
        </p>

        <PreliminaryScheduleForm submitLabel="Pateikti" />
      </div>
    </>
  );
};

export default SecondPhaseYes;
