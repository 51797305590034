import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";

import { generateCertificate } from "../../utils/certificate";
import { useSelf } from "../../hooks/self";
import InfoPopupContext from "../../info-popup-context";
import Button from "../../components/button";

/* eslint-disable react/jsx-props-no-spreading */
const DownloadCertificate = ({ children, ...props }) => {
  const { userData } = useSelf();

  const [isGenerating, setIsGenerating] = useState(false);

  const infoPopupContext = useContext(InfoPopupContext);

  const downloadCertificate = useCallback(() => {
    setIsGenerating(true);

    generateCertificate(userData)
      .catch(err => {
        infoPopupContext.display({
          error: true,
          message: "Nepavyko sugeneruoti sertifikato"
        });
        throw err;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  }, [userData]);

  return (
    <Button onClick={downloadCertificate} busy={isGenerating} {...props}>
      {children}
    </Button>
  );
};

DownloadCertificate.propTypes = {
  children: PropTypes.node
};

DownloadCertificate.defaultProps = {
  children: null
};

export default DownloadCertificate;
