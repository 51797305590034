import React, { useMemo, useCallback, useState, useContext } from "react";
import { useDispatch } from "react-redux";

import ACTIVITY from "../../../enums/activity.json";
import EXPERIENCE from "../../../enums/experience.json";
import LICENCE_COLLECTION_METHOD from "../../../enums/licence-collection-method.json";
import MUNICIPALITY from "../../../enums/municipality.json";
import YES_NO from "../../../enums/yes-no.json";

import { useTranslations } from "../../hooks/translations";
import { submitAdditionalInfo } from "../../api/volunteers";
import { updateSelf as updateSelfAction } from "../../state/self/self-actions";
import { ELECTION_DATE_RANGE } from "../../utils/calendar";
import { parseTimeString } from "../../utils/time";
import { REQUIRED, TIME, MAX_LENGTH, PERSONAL_CODE } from "../../utils/validators";
import InfoPopupContext from "../../info-popup-context";
import Form from "../../components/form";
import Dropdown from "../../components/dropdown";
import Textarea from "../../components/textarea";
import FormInput from "../../components/form-input";
import TimeInput from "../../components/time-input";
import Button from "../../components/button";
import StatusModal from "../../components/status-modal";

import styles from "./additional-info.module.scss";

const scheduleFields = ELECTION_DATE_RANGE.reduce((fields, _, dateIndex) => {
  const idFrom = `scheduleDay${dateIndex}From`;
  const idTo = `scheduleDay${dateIndex}To`;
  fields[idFrom] = {
    value: "",
    validators: [
      {
        validate: value => !value || TIME(value),
        message: "badTimeFormat"
      }
    ],
    revalidateField: idTo
  };
  fields[idTo] = {
    value: "",
    validators: [
      {
        validate: value => !value || TIME(value),
        message: "badTimeFormat"
      },
      {
        validate: (value, formData) => {
          const timeFrom = parseTimeString(formData[idFrom].value);
          const timeTo = parseTimeString(value);

          if (!timeFrom || !timeTo) {
            return true;
          }

          if (timeFrom.hours < timeTo.hours) {
            return true;
          }
          if (timeFrom.hours === timeTo.hours) {
            return timeFrom.minutes < timeTo.minutes;
          }

          return false;
        },
        message: "timeFromTo"
      }
    ]
  };
  return fields;
}, {});

const FIELDS = {
  personalCode: {
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "personalCodeRequired"
      },
      {
        validate: PERSONAL_CODE,
        message: "personalCodeInvalid"
      }
    ]
  },
  car: {
    required: true,
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "carRequired"
      }
    ]
  },
  licenceCollectionMethod: {
    required: true,
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "licenceCollectionMethodRequired"
      }
    ]
  },
  municipalities: {
    value: [],
    validators: [
      {
        validate: MAX_LENGTH(6),
        message: "tooManyMunicipalities"
      }
    ]
  },
  ...scheduleFields,
  scheduleVoteCount: {
    value: ""
  },
  experience: {
    value: []
  },
  activities: {
    value: []
  },
  otherHelp: {
    value: "",
    validators: [
      {
        validate: (value, formData) =>
          !formData.activities.value.includes("OTHER") || REQUIRED(value),
        message: "otherHelpRequired"
      },
      {
        validate: MAX_LENGTH(400),
        message: "otherHelpTooLong"
      }
    ]
  }
};

const processSchedule = formData =>
  ELECTION_DATE_RANGE.map((date, dateIndex) => {
    const idFrom = `scheduleDay${dateIndex}From`;
    const idTo = `scheduleDay${dateIndex}To`;

    const timeFrom = parseTimeString(formData[idFrom]);
    const timeTo = parseTimeString(formData[idTo]);

    let dateFrom;
    if (timeFrom) {
      dateFrom = new Date(date);
      dateFrom.setHours(timeFrom.hours, timeFrom.minutes, 0, 0);
    }

    let dateTo;
    if (timeTo) {
      dateTo = new Date(date);
      dateTo.setHours(timeTo.hours, timeTo.minutes, 0, 0);
    }

    return dateFrom || dateTo
      ? {
          from: dateFrom,
          to: dateTo
        }
      : null;
  }).filter(value => !!value);

const AdditionalInfo = () => {
  const t = useTranslations("clientRoutes.additionalInfo");

  const enums = useTranslations("enums");

  const municipalityDropdownValues = useMemo(
    () =>
      Object.keys(MUNICIPALITY).map(key => ({
        key,
        value: enums.municipality[key]
      })),
    []
  );

  const experienceDropdownValues = useMemo(
    () => Object.keys(EXPERIENCE).map(key => ({ key, value: enums.experience[key] })),
    []
  );

  const activitiesDropdownValues = useMemo(
    () => Object.keys(ACTIVITY).map(key => ({ key, value: enums.activity[key] })),
    []
  );

  const licenceCollectionMethodDropdownValues = useMemo(
    () =>
      Object.keys(LICENCE_COLLECTION_METHOD).map(key => ({
        key,
        value: enums.licenceCollectionMethod[key]
      })),
    []
  );

  const yesNoDropdownValues = useMemo(
    () =>
      Object.keys(YES_NO).map(key => ({
        key,
        value: enums.yesNo[key]
      })),
    []
  );

  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const [submitStatus, setSubmitStatus] = useState(null);

  const infoPopupContext = useContext(InfoPopupContext);

  const submitForm = useCallback(
    formData => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      const data = {
        ...formData,
        availability: processSchedule(formData)
      };

      Object.keys(data).forEach(key => {
        if (key.startsWith("scheduleDay")) {
          delete data[key];
        }
      });

      if (!data.activities.includes("OTHER")) {
        delete data.otherHelp;
      }

      submitAdditionalInfo(data)
        .then(
          () => {
            infoPopupContext.display({
              message: t.success
            });
            // Timeout necessary because calling `display` on this context
            // triggers a re-render of this component
            setTimeout(() => {
              dispatch(updateSelfAction(data));
              window.scrollTo({
                top: 0
              });
            });
          },
          () => {
            setSubmitStatus({
              error: true,
              ...t.error
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [submitting, dispatch, setSubmitStatus, setSubmitting]
  );

  return (
    <>
      <Form fields={FIELDS} onSubmit={submitForm} displayGlobalError errorMessages={t.errors}>
        {({ setFieldValue, fields }) => (
          <>
            <h1 className="bp-heading">{t.heading}</h1>

            <p className="bp-paragraph">{t.description}</p>

            <Dropdown
              id="car"
              label={t.car}
              options={yesNoDropdownValues}
              field={fields.car}
              onChange={setFieldValue}
              placeholder={t.car}
            />

            <Dropdown
              id="licenceCollectionMethod"
              label={t.licenceCollectionMethod}
              options={licenceCollectionMethodDropdownValues}
              field={fields.licenceCollectionMethod}
              onChange={setFieldValue}
              placeholder={t.licenceCollectionMethod}
            />

            <Dropdown
              id="experience"
              label={t.experience}
              options={experienceDropdownValues}
              field={fields.experience}
              onChange={setFieldValue}
              multiple
            />

            <Dropdown
              id="activities"
              label={t.activities}
              options={activitiesDropdownValues}
              field={fields.activities}
              onChange={setFieldValue}
              multiple
            />

            {fields.activities.value.includes("OTHER") ? (
              <Textarea
                id="otherHelp"
                label={t.otherHelp}
                field={fields.otherHelp}
                onChange={setFieldValue}
              />
            ) : null}

            <FormInput
              id="personalCode"
              label={
                <>
                  {t.personalCode} <span className="bp-asterisk" />
                </>
              }
              placeholder={t.personalCode}
              type="number"
              field={fields.personalCode}
              onChange={setFieldValue}
              className={styles.numberPicker}
            />

            <div className={styles.whyPersonalCode}>
              <h2 className="bp-visually-hidden" id="why-personal-code">
                {t.whyPersonalCode.heading}
              </h2>

              {t.whyPersonalCode.reasons.map((reason, index) => (
                <p key={reason} className="bp-paragraph">
                  {index === 0 ? <span className="bp-asterisk" /> : null} {reason}
                </p>
              ))}
            </div>

            <h2 className={styles.scheduleHeading}>{t.preliminaryHeading}</h2>

            <p className="bp-paragraph">{t.preliminaryDescription}</p>

            <Dropdown
              id="municipalities"
              label={t.municipalities}
              options={municipalityDropdownValues}
              field={fields.municipalities}
              onChange={setFieldValue}
              placeholder={t.municipalities}
              multiple
            />

            <div className="bp-label">{t.schedule.heading}</div>

            {ELECTION_DATE_RANGE.map((date, dateIndex) => {
              const baseId = `scheduleDay${dateIndex}`;

              const idFrom = `${baseId}From`;
              const idTo = `${baseId}To`;

              const dateLabel = `${enums.month[date.getMonth()]} ${date.getDate()} ${
                enums.timeUnit.DAY.short
              }`;

              const ariaLabelId = `${baseId}Label`;
              const labelId = `${baseId}DateLabel`;
              const labelFromId = `${idFrom}Label`;
              const labelToId = `${idTo}Label`;

              const fieldFrom = fields[idFrom];
              const fieldTo = fields[idTo];

              return (
                <div key={date.toString()}>
                  <div className={`bp-between ${styles.scheduleRow}`}>
                    <div className="bp-visually-hidden" id={ariaLabelId}>
                      {t.schedule.ariaLabel}
                    </div>
                    <div className={styles.label} id={labelId}>
                      {dateLabel}
                    </div>

                    <div className="bp-between">
                      <div className={styles.label} id={labelFromId}>
                        {t.schedule.labelFrom}
                      </div>
                      <TimeInput
                        id={idFrom}
                        field={fieldFrom}
                        onChange={setFieldValue}
                        aria-labelledby={`${ariaLabelId} ${labelId} ${labelFromId}`}
                      />
                    </div>

                    <div className="bp-between">
                      <div className={styles.label} id={labelToId}>
                        {t.schedule.labelTo}
                      </div>
                      <TimeInput
                        id={idTo}
                        field={fieldTo}
                        onChange={setFieldValue}
                        aria-labelledby={`${ariaLabelId} ${labelId} ${labelToId}`}
                      />
                    </div>
                  </div>
                  {fieldFrom.error || fieldTo.error ? (
                    <div className={`bp-error-message ${styles.timeErrorMessage}`}>
                      <div>{fieldFrom.error}</div>
                      <div>{fieldTo.error}</div>
                    </div>
                  ) : null}
                </div>
              );
            })}

            <Dropdown
              id="scheduleVoteCount"
              label={t.scheduleVoteCount}
              options={yesNoDropdownValues}
              field={fields.scheduleVoteCount}
              onChange={setFieldValue}
              placeholder={enums.yesNo.DONT_KNOW}
            />

            <Button type="submit" className="bp-standard bp-submit-button" busy={submitting}>
              {t.submit}
            </Button>
          </>
        )}
      </Form>

      {submitStatus && submitStatus.error ? (
        <StatusModal
          error
          heading={submitStatus.heading}
          description={submitStatus.description}
          onClose={() => setSubmitStatus(null)}
        />
      ) : null}
    </>
  );
};

export default AdditionalInfo;
