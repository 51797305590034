import React, { useCallback } from "react";

import VIOLATION_TYPE from "../../enums/violation-type.json";
import MUNICIPALITY from "../../enums/municipality.json";
// import CONSTITUENCY from "../../enums/constituency.json";
import REPORT_STATUS from "../../enums/report-status.json";

import { useTranslations } from "../hooks/translations";
import TablePage from "../components/table-page";
import DateInput from "../components/date-input";

import { useViolationReports } from "./hooks/violation-reports";

import styles from "./violation-reports.module.scss";

const COLUMNS = [
  {
    key: "id",
    accessor: "id",
    allowGlobalFilter: true
  },
  {
    key: "violationType",
    accessor: "violationType",
    filter: {
      comparator: "includesComparator",
      options: Object.keys(VIOLATION_TYPE),
      translationKey: "violationType",
      multiple: true
    }
  },
  {
    key: "describe",
    accessor: "describe",
    allowGlobalFilter: true
  },
  {
    key: "municipality",
    accessor: "municipality",
    filter: {
      comparator: "includesComparator",
      options: Object.keys(MUNICIPALITY),
      translationKey: "municipality",
      multiple: true
    }
  },
  {
    key: "status",
    accessor: "status",
    filter: {
      comparator: "includesComparator",
      options: Object.keys(REPORT_STATUS),
      translationKey: "reportStatus",
      multiple: true
    }
  },
  // {
  //   key: "constituency",
  //   accessor: "constituency",
  //   filter: {
  //     comparator: "includesComparator",
  //     options: Object.keys(CONSTITUENCY),
  //     translationKey: "constituency",
  //     multiple: true
  //   }
  // },
  {
    key: "createdAt",
    accessor: "createdAtString",
    filter: {
      /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
      component: ({ value, onChange, ...props }) => (
        <DateInput
          field={{ value }}
          onChange={(_, __, newValue) => onChange(newValue)}
          {...props}
        />
      ),
      /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
      comparator: (rows, _, value) => {
        const dateFrom = new Date(`${value}T00:00:00`);

        const dateTo = new Date(`${value}T23:59:59`);

        return !Number.isNaN(dateFrom.valueOf()) && !Number.isNaN(dateTo.valueOf())
          ? rows.filter(
              row => row.original.createdAt >= dateFrom && row.original.createdAt <= dateTo
            )
          : rows;
      },
      initialValue: "",
      columns: 2
    }
  },
  {
    key: "pollingStation",
    accessor: "pollingStation"
  },
  {
    key: "name",
    accessor: "name"
  },
  {
    key: "surname",
    accessor: "surname"
  },
  {
    key: "phone",
    accessor: "phone"
  },
  {
    key: "email",
    accessor: "email"
  },
  {
    key: "files",
    accessor: "files"
  }
];

const HIDDEN_COLUMNS = [
  "id",
  "name",
  "surname",
  "phone",
  "email",
  "constituency",
  "pollingStation",
  "files",
  "createdAtDate"
];

const ViolationReports = () => {
  const t = useTranslations("clientRoutes.violationReports");

  const { loading, reports } = useViolationReports();

  const transformCSVRow = useCallback(
    row => ({
      ...row,
      files: row.files ? row.files.map(({ url }) => url).join("\n") : ""
    }),
    []
  );

  return (
    <TablePage
      id="violation-report-list"
      columns={COLUMNS}
      hiddenColumns={HIDDEN_COLUMNS}
      loading={loading}
      data={reports}
      path="/dashboard/violation-reports"
      title={t.title}
      heading={t.heading}
      filename={t.filename}
      headerTranslations={t}
      transformCSVRow={transformCSVRow}
      className={styles.table}
    />
  );
};

export default ViolationReports;
