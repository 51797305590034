import React from "react";

import ButtonLink from "../../components/button-link";

const Underage = () => (
  <>
    <h1 className="bp-heading">Labas, „Baltųjų pirštinių“ drauge!</h1>

    <p className="bp-paragraph">
      Ačiū, kad išreiškei norą dalyvauti „Baltųjų pirštinių“ pirštinių veikloje. Deja,
      nepriklausomais rinkimų stebėtojais gali tapti tik pilnametystės sulaukę asmenys, tad šių
      rinkimų stebėti Tu negali. Sulaukus pilnametystės Tavo paskyra aktyvuosis automatiškai ir
      galėsi vykti stebėti rinkimų.
    </p>

    <p className="bp-paragraph">Tuo tarpu, jei šių rinkimų kampanijos metu pastebėjai, kad:</p>

    <ul className="bp-styled-list">
      <li>
        politiniai plakatai / straipsniai / įrašai socialiniuose tinkluose nepažymėti kaip politinė
        reklama;
      </li>
      <li>rinkėjams siūlomos dovanos (ledai, maistas, gėrimai ir pan.);</li>
      <li>organizuojami nemokami koncertai, pramogos ar paslaugos rinkėjams;</li>
      <li>įtartinais automobiliais rinkėjai vežami į apylinkę, perkami jų balsai –</li>
    </ul>

    <p className="bp-center">
      <ButtonLink to="/report-violation" className="bp-standard">
        pranešk apie tai mums!
      </ButtonLink>
    </p>
  </>
);

export default Underage;
