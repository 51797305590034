import React, { useCallback, useMemo, useContext, useState } from "react";
import { useParams } from "@reach/router";
import { useDispatch } from "react-redux";

import REPORT_STATUS from "../../enums/report-status.json";

import { updateViolationReport } from "../api/violation-reports";
import { useTranslations } from "../hooks/translations";
import { updateViolationReport as updateViolationReportAction } from "../state/violation-reports/violation-report-actions";
import SEO from "../components/seo";
import Spinner from "../components/spinner";
import SpinnerOverlay from "../components/spinner-overlay";
import Listbox from "../components/listbox";
import InfoPopupContext from "../info-popup-context";

import { useViolationReport } from "./hooks/violation-reports";

import styles from "./violation-report-details.module.scss";

const STATUS_CLASSES = {
  [REPORT_STATUS.NEW]: styles.new,
  [REPORT_STATUS.INVESTIGATING]: styles.investigating,
  [REPORT_STATUS.CEC]: styles.cec,
  [REPORT_STATUS.POLICE]: styles.police,
  [REPORT_STATUS.RESOLVED]: styles.resolved,
  [REPORT_STATUS.UNVERIFIED]: styles.unverified
};

const ViolationReportDetails = () => {
  const t = useTranslations("clientRoutes.violationReportDetails");

  const enums = useTranslations("enums");

  const { reportId } = useParams();

  const { loading, report } = useViolationReport(reportId);

  const statusDropdownValues = useMemo(
    () =>
      Object.keys(REPORT_STATUS).map(key => ({
        key,
        value: enums.reportStatus[key]
      })),
    []
  );

  const dispatch = useDispatch();

  const [updating, setUpdating] = useState(false);

  const infoPopupContext = useContext(InfoPopupContext);

  const submitStatusUpdate = useCallback(
    newStatus => {
      if (updating) {
        return;
      }

      setUpdating(true);

      const update = {
        status: newStatus
      };

      updateViolationReport(reportId, update)
        .then(
          () => {
            dispatch(updateViolationReportAction(reportId, update));
            infoPopupContext.display({
              message: t.statusUpdated
            });
          },
          () => {
            infoPopupContext.display({
              error: true,
              message: t.statusUpdateFailed
            });
          }
        )
        .finally(() => {
          setUpdating(false);
        });
    },
    [reportId, dispatch]
  );

  return (
    <>
      <SEO title={t.title} robots="noindex, nofollow" />

      <section>
        <h1>{t.heading}</h1>

        {loading || !report ? (
          <SpinnerOverlay />
        ) : (
          <div className="bp-container bp-left">
            <div className={`bp-card ${styles.card}`}>
              <h2>{t.details}</h2>
              <div>
                <span id="label-date" className="bp-visually-hidden">
                  {t.date}
                </span>
                <span aria-labelledby="label-date" className={STATUS_CLASSES[report.raw.status]}>
                  {report.createdAtString}
                </span>
              </div>
              <div className={styles.row}>
                <div className={styles.column}>
                  <span id="label-status" className="bp-large-label">
                    {t.status}
                  </span>
                  <div
                    className={`${styles.statusDropdownContainer} ${updating ? styles.busy : ""}`}
                  >
                    <Listbox
                      labelledBy="label-status"
                      id="violation-report-status"
                      options={statusDropdownValues}
                      value={report.raw.status}
                      onChange={submitStatusUpdate}
                      buttonClassName={`${styles.statusDropdown} ${
                        STATUS_CLASSES[report.raw.status]
                      }`}
                      disabled={updating}
                    />
                    {updating ? <Spinner dotClassName={styles.spinnerDot} /> : null}
                  </div>
                </div>
                <p>
                  <span id="label-type" className="bp-large-label">
                    {t.type}
                  </span>
                  <span aria-labelledby="label-type">{report.violationType}</span>
                </p>
                <p className={styles.fullWidth}>
                  <span id="label-description" className="bp-large-label">
                    {t.description}
                  </span>
                  <span aria-labelledby="label-description">{report.describe}</span>
                </p>
                <div className={styles.column}>
                  <p className={styles.fullWidth}>
                    <span id="label-files" className="bp-large-label">
                      {t.files}
                    </span>
                  </p>
                  {report.files && report.files.length > 0 ? (
                    <ul
                      aria-labelledby="label-files"
                      className={`${styles.fullWidth} ${styles.files}`}
                    >
                      {report.files.map(file => (
                        <li key={file.url}>
                          <a
                            href={file.url}
                            className="bp-link"
                            download
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {file.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )}
                </div>

                {!report.partial ? (
                  <>
                    <p>
                      <span id="label-municipality" className="bp-large-label">
                        {t.municipality}
                      </span>
                      <span aria-labelledby="label-municipality">{report.municipality}</span>
                    </p>
                    <p>
                      <span id="label-constituency" className="bp-large-label">
                        {t.constituency}
                      </span>
                      <span aria-labelledby="label-constituency">{report.constituency || "-"}</span>
                    </p>
                    <p>
                      <span id="label-polling-station" className="bp-large-label">
                        {t.pollingStation}
                      </span>
                      <span aria-labelledby="label-polling-station">
                        {report.pollingStation || "-"}
                      </span>
                    </p>
                  </>
                ) : null}
              </div>

              {!report.partial ? (
                <>
                  <h2>{t.reporter}</h2>
                  <div className={styles.row}>
                    <p className={styles.fullWidth}>
                      <span id="label-name" className="bp-large-label">
                        {t.name}
                      </span>
                      <span aria-labelledby="label-name">
                        {report.name} {report.surname}
                      </span>
                    </p>
                    <p>
                      <span id="label-phone" className="bp-large-label">
                        {t.phone}
                      </span>
                      {report.phone ? (
                        <a
                          href={`tel:${report.phone}`}
                          className="bp-link"
                          aria-labelledby="label-phone"
                        >
                          {report.phone}
                        </a>
                      ) : (
                        "-"
                      )}
                    </p>
                    <p>
                      <span id="label-email" className="bp-large-label">
                        {t.email}
                      </span>
                      {report.email ? (
                        <a
                          href={`mailto:${report.email}`}
                          className="bp-link"
                          aria-labelledby="label-email"
                        >
                          {report.email}
                        </a>
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ViolationReportDetails;
