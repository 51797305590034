export const QUESTIONNAIRES = {
  // =====SECOND PHASE START=====
  ADVANCE_MUNICIPALITY:
    "https://docs.google.com/forms/d/e/1FAIpQLScq1rxctw4AkSGNTbriHouZJOAjED4t0cS-G0bfOAmYhOfG6Q/viewform?usp=pp_url&entry.194097740=<<NAME>>&entry.685289548=<<PHONE>>",
  ADVANCE_SPECIAL:
    "https://docs.google.com/forms/d/e/1FAIpQLSelLo54WcFdkJK-bZLNiXcGMPctymtb2tEF_FdK19l-f8aNIw/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.1315407010=<<PHONE>>",
  ADVANCE_HOME:
    "https://docs.google.com/forms/d/e/1FAIpQLSfx5928IBr1gAl02u4KSQm1G2p2nzivH7H6ZZxeLroOpfQq5g/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.1849708676=<<PHONE>>",
  ELECTION_DAY:
    "https://docs.google.com/forms/d/e/1FAIpQLSeQMFG63RnRmj9ynDKAPeuF1AL-jxpFo5BbPIXg0xWg8IrNNg/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.1391793873=<<PHONE>>",
  VOTE_COUNT:
    "https://docs.google.com/forms/d/e/1FAIpQLSfg18KWN9pnZMrTu2L39ZVT2XjEeqPgMyHMh_Hbvwo2hoqb1Q/viewform?usp=pp_url&entry.2098988459=<<NAME>>&entry.979519746=<<PHONE>>"
  // =====SECOND PHASE END=====
};
