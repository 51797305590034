import { ELECTION_STAGES } from "../../content/calendar";
import { SCHEDULES } from "../../content/schedules";

const SCHEDULES_WITH_DATE_RANGE = SCHEDULES.map(schedule => {
  const { start, end, date } = ELECTION_STAGES.find(({ type }) => type === schedule.type);

  const startDate = new Date(date || start);
  const endDate = end ? new Date(end) : null;

  return {
    ...schedule,
    month: startDate.getMonth(),
    start: startDate.getDate(),
    end: endDate ? endDate.getDate() : null
  };
});

export { SCHEDULES_WITH_DATE_RANGE };
