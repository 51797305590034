import React, { useMemo, useState, useEffect } from "react";

import { ELECTION_STAGES } from "../../../content/calendar";
import MUNICIPALITY_REGION_MAP from "../../../content/municipality-region.json";
import REGION from "../../../enums/region.json";

import { useTranslations } from "../../hooks/translations";
import { useLinkTranslation } from "../../hooks/link-translation";
import { useSelf } from "../../hooks/self";
import { SCHEDULES_WITH_DATE_RANGE } from "../../utils/schedules";
import { filterElectionStagesByDates, STAGES_BY_DATE } from "../../utils/calendar";
import LocalizedLink from "../../components/localized-link";
import FileLinkList from "../../components/file-link-list";

import DateFilter from "./date-filter";

import styles from "./schedules.module.scss";

const saveSelectedDate = selectedDate => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem("schedule-selected-date", selectedDate);
  }
};

const restoreSelectedDate = () => {
  if (typeof window !== "undefined") {
    const dateString = window.sessionStorage.getItem("schedule-selected-date");
    if (dateString === null) {
      return undefined;
    }
    if (dateString === "null") {
      return null;
    }
    if (dateString) {
      const date = new Date(dateString);
      return Number.isNaN(date.valueOf()) ? undefined : date;
    }
  }

  return undefined;
};

const Schedules = () => {
  const t = useTranslations("clientRoutes.schedules");

  const enums = useTranslations("enums");

  const { userData } = useSelf();

  const userDates = useMemo(() => userData.availability.map(({ dateTime }) => dateTime), [
    userData.availability
  ]);

  const initialSelectedDate = useMemo(() => {
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);

    const value = restoreSelectedDate();

    if (value === null) {
      return null;
    }

    if (typeof value === "undefined" || value < todaysDate) {
      return todaysDate;
    }

    if (userDates.some(allowedDate => allowedDate.valueOf() === value.valueOf())) {
      return value;
    }

    return null;
  }, []);

  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);

  const remainingStages = useMemo(() => filterElectionStagesByDates(userDates), []);

  const matchingRegions = useMemo(
    () =>
      Object.keys(REGION).filter(regionKey =>
        userData.municipalities.some(
          municipality => MUNICIPALITY_REGION_MAP[municipality] === regionKey
        )
      ),
    [userData.municipalities]
  );

  const allowedStages = useMemo(
    () =>
      (
        (selectedDate && STAGES_BY_DATE[selectedDate.valueOf()]) ||
        remainingStages ||
        ELECTION_STAGES
      ).map(({ type }) => type),
    [selectedDate, remainingStages]
  );

  const filteredSchedules = useMemo(() => {
    return SCHEDULES_WITH_DATE_RANGE.filter(
      ({ type, region }) => allowedStages.includes(type) && matchingRegions.includes(region)
    );
  }, [allowedStages, matchingRegions]);

  const groupedSchedules = useMemo(() => {
    const allRegionSchedules = [];

    filteredSchedules.forEach(({ region, ...details }) => {
      let regionSchedules = allRegionSchedules.find(schedule => schedule.region === region);

      if (!regionSchedules) {
        regionSchedules = {
          region,
          municipalities: userData.municipalities
            .filter(municipality => MUNICIPALITY_REGION_MAP[municipality] === region)
            .map(municipality => enums.municipality[municipality])
            .join(", "),
          schedules: []
        };

        allRegionSchedules.push(regionSchedules);
      }

      regionSchedules.schedules.push({
        ...details
      });
    });

    return allRegionSchedules;
  }, [filteredSchedules, userData.municipalities]);

  const userMunicipalitiesNode = useMemo(
    () =>
      userData.municipalities.map((key, index) => (
        <span key={key}>
          <b>{enums.municipality[key]}</b>
          {index < userData.municipalities.length - 1 ? ", " : ""}
        </span>
      )),
    [userData.municipalities]
  );

  const userDatesNode = useMemo(
    () =>
      userDates.map((date, index) => (
        <span key={date}>
          <b>
            {enums.month[date.getMonth()]} {date.getDate()} d.
          </b>
          {index < userDates.length - 1 ? ", " : ""}
        </span>
      )),
    [userDates]
  );

  const editSchedulePath = useLinkTranslation("edit");

  useEffect(() => {
    saveSelectedDate(selectedDate);
  }, [selectedDate]);

  return (
    <>
      <p className="bp-paragraph">
        Tu pasirinkai stebėti balsavimą {userDatesNode} šiose savivaldybėse:{" "}
        {userMunicipalitiesNode}.{" "}
        <LocalizedLink to={editSchedulePath} className={`bp-link ${styles.editLink}`}>
          Keisti&nbsp;pasirinkimą
        </LocalizedLink>
        .
      </p>

      <DateFilter
        allowedDates={userDates}
        value={selectedDate}
        onChange={setSelectedDate}
        className={styles.dateFilter}
      />

      <ul>
        {groupedSchedules.map(({ region, municipalities, schedules }) => (
          <li key={region}>
            <h3 className={styles.regionHeading}>
              {enums.region[region]} {t.schedules}{" "}
            </h3>

            {municipalities ? <p className={styles.municipalityList}>{municipalities}</p> : null}

            <FileLinkList
              items={schedules.map(({ type, file, month, start, end }) => ({
                key: type,
                file,
                label: (
                  <>
                    {enums.electionDay[type]} ({enums.month[month]}
                    &nbsp;
                    {end ? (
                      <>
                        {start}
                        &#8209;
                        {end}
                      </>
                    ) : (
                      start
                    )}
                    &nbsp;
                    {t.d})
                  </>
                )
              }))}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default Schedules;
