import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { Router } from "@reach/router";

import { ROUTE_CONFIG } from "../client-routes/route-config";

import { useLinkTranslation } from "../hooks/link-translation";
import { useTranslations } from "../hooks/translations";
import { useSelf } from "../hooks/self";
import SEO from "../components/seo";
import SpinnerOverlay from "../components/spinner-overlay";
import DashboardSidebar from "../components/dashboard/sidebar";
import ProtectedRoute from "../components/protected-route";

import styles from "./dashboard.module.scss";

export const query = graphql`
  query DashboardQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        dashboard
      }
      clientRoutes {
        volunteers
        volunteerDetails
        volunteerRoles
        violationReports
        violationReportDetails
        myStatus
        additionalInfo
        schedules
        questionnaires
        preliminarySchedule
      }
      enums {
        activity
        constituency
        electionDay
        experience
        licenceCollectionMethod
        licenceStatus
        month
        municipality
        region
        reportStatus
        timeUnit
        trainingStatus
        userRole
        userStatus
        violationType
        weekday
        yesNo
      }
      form
      sitemap
      logoLink
      table
    }
  }
`;

const DashboardPage = () => {
  const t = useTranslations("pages.dashboard");

  const translateLink = useLinkTranslation();

  const basePath = useLinkTranslation("/dashboard");

  const { initializing } = useSelf();

  const routes = useMemo(
    () =>
      ROUTE_CONFIG.map(route => ({
        ...route,
        path: translateLink(route.path)
      })),
    []
  );

  return (
    <>
      <SEO title={t.title} robots="noindex, nofollow" />

      {initializing ? (
        <SpinnerOverlay />
      ) : (
        <div className={`bp-full-screen ${styles.container}`}>
          <DashboardSidebar className={styles.sidebar} />

          <Router basepath={basePath} className={styles.main}>
            {routes.map(({ path, Component, roles, customAccessCheck }) => (
              <ProtectedRoute
                key={path}
                path={path}
                component={Component}
                roles={roles}
                customAccessCheck={customAccessCheck}
              />
            ))}
          </Router>
        </div>
      )}
    </>
  );
};

export default DashboardPage;
