import React from "react";
import PropTypes from "prop-types";

import IconCheck from "../assets/icons/check.svg";
import { FORM_FIELD } from "../prop-types";

import styles from "./toggle-token.module.scss";

const ToggleToken = ({ id, children, field, onChange }) => (
  <>
    <input
      type="checkbox"
      id={id}
      className={`bp-visually-hidden ${styles.checkbox}`}
      aria-labelledby={`${id}-children`}
      checked={field.value}
      onChange={e => onChange(id, field, e.target.checked)}
    />
    <label htmlFor={id} className={styles.label}>
      <span className={`${styles.fakeCheckbox} ${field.error ? styles.error : ""}`}>
        <IconCheck className={`bp-stroke bp-3 ${styles.check}`} />
      </span>
      {children}
    </label>
  </>
);

ToggleToken.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  field: FORM_FIELD.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ToggleToken;
