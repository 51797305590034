import React, { useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import IconArrow from "../../assets/icons/arrow.svg";

import { updateUser } from "../../api/volunteers";
import { updateUser as updateUserAction } from "../../state/users/user-actions";
import { useSelf } from "../../hooks/self";
import { useTranslations } from "../../hooks/translations";
import { useUser } from "../hooks/users";
import { REQUIRED } from "../../utils/validators";
import InfoPopupContext from "../../info-popup-context";
import Form from "../../components/form";
import Textarea from "../../components/textarea";
import Button from "../../components/button";
import LocalizedLink from "../../components/localized-link";

import styles from "./user-comments.module.scss";

const FIELDS = {
  comment: {
    value: "",
    validators: [
      {
        validate: REQUIRED,
        message: "commentRequired"
      }
    ]
  }
};

const UserComments = ({ userId, className }) => {
  const t = useTranslations("clientRoutes.volunteerDetails");

  const { claims, user: self } = useSelf();

  const hasAdminAccess = useMemo(() => claims.ADMIN, [claims]);

  const allowNewComment = useMemo(() => hasAdminAccess, [hasAdminAccess]);

  const { loading, user } = useUser(userId);

  const [addingComment, setAddingComment] = useState(false);

  const dispatch = useDispatch();

  const infoPopupContext = useContext(InfoPopupContext);

  const addComment = useCallback(
    ({ comment }) => {
      if (addingComment) {
        return null;
      }

      setAddingComment(true);

      return updateUser(userId, {
        comment
      })
        .then(
          () => {
            dispatch(
              updateUserAction(userId, {
                comment: {
                  text: comment,
                  createdAt: new Date(),
                  createdBy: {
                    userId: self.uid,
                    name: self.displayName,
                    email: self.email
                  }
                }
              })
            );
            return true;
          },
          () => {
            infoPopupContext.display({
              message: t.commentFailed,
              error: true
            });
          }
        )
        .finally(() => {
          setAddingComment(false);
        });
    },
    [setAddingComment, dispatch, userId, infoPopupContext, user, self]
  );

  return loading ? null : (
    <div className={className}>
      <h3 className={styles.heading}>{t.comments}</h3>

      <div>
        {user.comments &&
          user.comments.map(comment => (
            <section
              key={`${comment.userId} ${comment.createdAtString} ${comment.text}`}
              className={styles.comment}
            >
              <header className={styles.commentHeader}>
                <address className={styles.commentAuthor}>
                  <LocalizedLink
                    to={`/dashboard/volunteers/${comment.createdBy.userId}`}
                    rel="author"
                    className="bp-link bp-accent"
                  >
                    {comment.createdBy.name}
                  </LocalizedLink>
                </address>{" "}
                <time dateTime={comment.createdAt.toISOString()} className={styles.commentDate}>
                  {comment.createdAtString}
                </time>
              </header>
              <p>{comment.text}</p>
            </section>
          ))}
      </div>

      {allowNewComment ? (
        <Form
          fields={FIELDS}
          onSubmit={addComment}
          errorMessages={{
            commentRequired: t.commentRequired
          }}
          className={styles.form}
        >
          {({ setFieldValue, fields }) => (
            <>
              <Textarea
                id="comment"
                label={t.newComment}
                field={fields.comment}
                onChange={setFieldValue}
                className={styles.textarea}
                labelClassName={`bp-large-label ${styles.label}`}
              />

              <Button
                type="submit"
                busy={addingComment}
                className={`bp-next ${styles.addCommentButton}`}
                spinnerDotClassName={styles.spinnerDot}
              >
                {t.addComment}
                <IconArrow className="bp-stroke" />
              </Button>
            </>
          )}
        </Form>
      ) : null}
    </div>
  );
};

UserComments.propTypes = {
  userId: PropTypes.string.isRequired,
  className: PropTypes.string
};

UserComments.defaultProps = {
  className: ""
};

export default UserComments;
