import { useContext, useCallback } from "react";

import InfoPopupContext from "../info-popup-context";
import { generateCSV } from "../utils/csv";

export const useCSVGenerator = () => {
  const infoPopup = useContext(InfoPopupContext);

  return useCallback(
    (data, { headers, fields, filename, success, error }) =>
      generateCSV(data, { headers, fields, filename, success, error }).then(
        () => {
          infoPopup.display({
            message: success
          });
        },
        () => {
          infoPopup.display({
            error: true,
            message: error
          });
        }
      ),
    [infoPopup]
  );
};
