import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";

import SSRContext from "../ssr-context";

import styles from "./time-input.module.scss";

const isTimeTypeSupported = () => {
  const input = document.createElement("input");
  input.setAttribute("type", "text");

  const notATimeValue = "not-a-time";
  input.setAttribute("value", notATimeValue);

  return input.value !== notATimeValue;
};

const isDelete = (previousValue, newValue) =>
  previousValue && previousValue.length === newValue.length + 1;

const formatDate = (newValue, previousValue) => {
  const deleting = isDelete(previousValue, newValue);

  let formattedTime = newValue.replace(/\D/g, "");

  const firstHoursDigit = parseInt(formattedTime[0], 10);

  let hours;
  let minutes;
  if (firstHoursDigit > 2) {
    hours = `0${firstHoursDigit}`;
    minutes = formattedTime.substr(1, 2);
  } else {
    hours = formattedTime.substr(0, 2);
    minutes = formattedTime.substr(2, 2);
  }

  if (firstHoursDigit === 2) {
    const secondHoursDigit = parseInt(hours[1], 10);

    if (secondHoursDigit > 3) {
      hours = `${firstHoursDigit}3`;
    }
  }

  const firstMinutesDigit = parseInt(minutes[0], 10);

  if (firstMinutesDigit > 5) {
    minutes = `0${firstMinutesDigit}`;
  }

  formattedTime = hours;

  if (hours.length === 2 && (minutes || !deleting)) {
    formattedTime = `${formattedTime}:${minutes}`;
  }

  return formattedTime;
};

/* eslint-disable react/jsx-props-no-spreading */
const TimeInput = ({ id, field, onChange, ...props }) => {
  const isSSR = useContext(SSRContext);

  const formatValue = useMemo(
    () => (!isSSR && isTimeTypeSupported() ? value => value : formatDate),
    [isSSR]
  );

  return (
    <input
      type="time"
      placeholder="--:--"
      id={id}
      name={id}
      value={field.value}
      onChange={e => onChange(id, field, formatValue(e.target.value, field.value))}
      className={`${field.error ? "bp-error" : null} ${styles.input}`}
      {...props}
    />
  );
};
/* eslint-enable react/jsx-props-no-spreading */

TimeInput.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimeInput;
