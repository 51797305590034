import React from "react";

import SEO from "../components/seo";
import DownloadCertificate from "./components/download-certificate";

const Certificate = () => (
  <section>
    <SEO title="Sertifikatas" robots="noindex, nofollow" />

    <h1>Stebėtojo sertifikatas</h1>

    <div className="bp-container">
      <DownloadCertificate className="bp-standard">Atsisiųsti sertifikatą</DownloadCertificate>

      <p>
        Ačiū Tau už skirtą laiką ir nuoširdžią savanorystę vardan skaidrių rinkimų! Susitiksime
        kituose rinkimuose!
      </p>

      <p>„Baltųjų pirštinių“ komanda</p>
    </div>
  </section>
);

export default Certificate;
