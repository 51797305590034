import React from "react";
import PropTypes from "prop-types";

import Modal from "./modal";
import Button from "./button";

const ConfirmationModal = ({ heading, description, yes, no, onClose }) => (
  <Modal onClose={onClose}>
    {({ closeModal }) => (
      <>
        <h1>{heading}</h1>

        <p>{description}</p>

        <div className="bp-between">
          <Button className="bp-inverse" onClick={() => closeModal(false)} autoFocus>
            {no}
          </Button>
          <Button className="bp-standard" onClick={() => closeModal(true)}>
            {yes}
          </Button>
        </div>
      </>
    )}
  </Modal>
);

ConfirmationModal.propTypes = {
  heading: PropTypes.string.isRequired,
  yes: PropTypes.string.isRequired,
  no: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string
};

ConfirmationModal.defaultProps = {
  description: null
};

export default ConfirmationModal;
