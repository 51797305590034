import React, { useMemo, useCallback, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useFilters, useGlobalFilter } from "react-table";

import IconArrow from "../assets/icons/arrow.svg";

import * as COMPARATORS from "../utils/comparators";
import { useTranslations } from "../hooks/translations";
import { useCSVGenerator } from "../hooks/csv-generator";

import Listbox from "./listbox";
import Button from "./button";

import styles from "./table.module.scss";

const PAGINATION_OPTIONS = ["5", "10", "20", "50", "100"];

const DEFAULT_PAGE_SIZE = 20;

const paginationOptionsDropdownValues = PAGINATION_OPTIONS.map(option => ({
  key: option,
  value: option
}));

const saveTableState = (id, state) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(`table-${id}`, JSON.stringify(state));
  }
};

const getTableState = id => {
  if (typeof window !== "undefined") {
    const stateJSON = window.sessionStorage.getItem(`table-${id}`);
    if (stateJSON) {
      try {
        return JSON.parse(stateJSON);
      } catch (err) {
        // Ignore
      }
    }
  }

  return {};
};

const GlobalFilter = ({ globalFilter, onChange, placeholder }) => {
  const [value, setValue] = useState(globalFilter);
  const [changeTimer, setChangeTimer] = useState(null);
  const triggerOnChange = useCallback(
    newValue => {
      setValue(newValue);

      clearTimeout(changeTimer);

      setChangeTimer(
        setTimeout(() => {
          onChange(newValue || undefined);
        }, 300)
      );
    },
    [changeTimer, setChangeTimer, setValue, onChange]
  );

  return (
    <input
      value={value || ""}
      onChange={e => triggerOnChange(e.target.value)}
      placeholder={placeholder}
      className={styles.searchInput}
    />
  );
};

GlobalFilter.propTypes = {
  globalFilter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};

GlobalFilter.defaultProps = {
  globalFilter: ""
};

const Table = React.forwardRef(
  ({ id, columns, data, onRowClick, hiddenColumns, className }, ref) => {
    const t = useTranslations("table");

    const savedState = useMemo(() => getTableState(id), []);

    const initialFilters = useMemo(() =>
      columns
        .filter(({ initialFilterValue }) => typeof initialFilterValue !== "undefined")
        .map(({ id: columnId, initialFilterValue }) => ({
          id: columnId,
          value: initialFilterValue
        }))
    );

    const instance = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: DEFAULT_PAGE_SIZE,
          hiddenColumns,
          filters: initialFilters,
          ...savedState
        },
        filterTypes: COMPARATORS
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );

    const {
      getTableProps,
      getTableBodyProps,
      prepareRow,
      allColumns,
      visibleColumns,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, filters, globalFilter },
      rows,
      setGlobalFilter
    } = instance;

    const getClickableRowProps = useCallback(
      row =>
        onRowClick
          ? {
              tabIndex: "0",
              onClick: () => {
                onRowClick(row.original);
              },
              onKeyDown: event => {
                if (event.key === "Enter") {
                  onRowClick(row.original);
                }
              },
              className: styles.clickableRow
            }
          : {},
      [onRowClick]
    );

    const allFilters = useMemo(
      () =>
        allColumns
          .filter(column => column.Filter)
          .map(column => ({
            key: column.id,
            Filter: () => column.render("Filter"),
            columns: column.filterColumns || 1
          })),
      [allColumns]
    );

    const generateCSV = useCSVGenerator();

    useEffect(() => {
      saveTableState(id, {
        pageSize,
        pageIndex,
        filters,
        globalFilter
      });
    }, [id, pageSize, pageIndex, filters, globalFilter]);

    useImperativeHandle(
      ref,
      () => ({
        generateCSV: ({ filename, success, error, transformRow = i => i }) => {
          generateCSV(
            rows.map(({ original }) => transformRow(original)),
            {
              headers: allColumns.map(({ headerName }) => headerName),
              fields: allColumns.map(({ accessorKey }) => accessorKey),
              filename,
              success,
              error
            }
          );
        }
      }),
      [generateCSV, rows, allColumns]
    );

    const displaying = useMemo(
      () =>
        rows.length > 0
          ? {
              from: pageIndex * pageSize + 1,
              to: pageIndex * pageSize + page.length,
              of: rows.length
            }
          : null,
      [page, pageIndex, pageSize, rows]
    );

    return (
      <>
        {allFilters.length > 0 ? (
          <div className={styles.filters}>
            {allFilters.map(({ key, Filter, columns: col }) => (
              <div key={key} className={`${styles.filter} ${styles[`column${col}`]}`}>
                <Filter />
              </div>
            ))}
          </div>
        ) : null}

        <GlobalFilter
          globalFilter={globalFilter}
          onChange={setGlobalFilter}
          placeholder={t.search}
        />

        {/* eslint-disable react/jsx-props-no-spreading */}
        <div className={`bp-card ${styles.tableWrapper}`}>
          <table {...getTableProps()} className={`${styles.table} ${className}`}>
            <thead>
              <tr>
                {visibleColumns.map(column => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} {...getClickableRowProps(row)}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* eslint-disable react/jsx-props-no-spreading */}

        <div className={`bp-between ${styles.paginationWrapper}`}>
          <span id={`${id}-table-page-size-label`} className="bp-visually-hidden">
            Choose table page size
          </span>
          <Listbox
            id={`${id}-table-page-size`}
            labelledBy={`${id}-table-page-size-label`}
            options={paginationOptionsDropdownValues}
            onChange={value => setPageSize(parseInt(value, 10))}
            value={`${pageSize}`}
          />

          <div>
            {displaying ? (
              <span>
                {t.displayed}
                &nbsp;
                <strong>{displaying.from}</strong>
                &nbsp;-&nbsp;
                <strong>{displaying.to}</strong>
                &nbsp;
                {t.outOf}
                &nbsp;
                <strong>{displaying.of}</strong>
              </span>
            ) : null}

            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className={styles.previousPage}
            >
              <IconArrow className="bp-stroke" />
            </Button>

            <Button onClick={() => nextPage()} className={styles.nextPage} disabled={!canNextPage}>
              <IconArrow className="bp-stroke" />
            </Button>
          </div>
        </div>
      </>
    );
  }
);

Table.propTypes = {
  id: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  data: PropTypes.arrayOf(PropTypes.object.isRequired),
  onRowClick: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string.isRequired),
  className: PropTypes.string
};

Table.defaultProps = {
  id: "unknown",
  data: [],
  onRowClick: null,
  hiddenColumns: [],
  className: ""
};

export default Table;
