import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";

import { getISODateString } from "../utils/time";
import { useTranslations } from "../hooks/translations";

import TableDropdownFilter from "./table-dropdown-filter";
// import TimeInput from "./time-input";

// import styles from "./table-time-filter.module.scss";

const TableTimeFilter = ({ id, label, dateOptions, value, onChange }) => {
  // const t = useTranslations("table");

  const enums = useTranslations("enums");

  const dateDropdownOptions = useMemo(() =>
    dateOptions.map(date => ({
      key: getISODateString(date),
      value: `${enums.weekday[date.getDay()]}, ${enums.month[date.getMonth()]} ${date.getDate()} d.`
    }))
  );

  const selectDate = useCallback(
    date => {
      onChange({
        ...value,
        date
      });
    },
    [value, onChange]
  );

  // const selectTimeFrom = useCallback(
  //   (_, __, timeFrom) => {
  //     onChange({
  //       ...value,
  //       timeFrom
  //     });
  //   },
  //   [value, onChange]
  // );

  // const selectTimeTo = useCallback(
  //   (_, __, timeTo) => {
  //     onChange({
  //       ...value,
  //       timeTo
  //     });
  //   },
  //   [value, onChange]
  // );

  // const selectedTimeFromValue = useMemo(
  //   () => ({
  //     value: value.timeFrom
  //   }),
  //   [value]
  // );

  // const selectedTimeToValue = useMemo(
  //   () => ({
  //     value: value.timeTo
  //   }),
  //   [value]
  // );

  return (
    <>
      {/* <div className={styles.container}> */}
      <TableDropdownFilter
        id={`${id}-date`}
        label={label}
        value={value.date}
        onChange={selectDate}
        options={dateDropdownOptions}
      />

      {/* <div>
        <label htmlFor={`${id}-table-filter-time-from`} className="bp-label">
          {t.from}
        </label>
        <TimeInput
          id={`${id}-table-filter-time-from`}
          field={selectedTimeFromValue}
          onChange={selectTimeFrom}
        />
      </div>

      <div>
        <label htmlFor={`${id}-table-filter-time-to`} className="bp-label">
          {t.to}
        </label>
        <TimeInput
          id={`${id}-table-filter-time-to`}
          field={selectedTimeToValue}
          onChange={selectTimeTo}
        />
      </div> */}
      {/* </div> */}
    </>
  );
};

TableTimeFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dateOptions: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired).isRequired,
  value: PropTypes.shape({
    date: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
};

TableTimeFilter.defaultProps = {
  value: {
    date: null,
    timeFrom: "",
    timeTo: ""
  }
};

export default TableTimeFilter;
