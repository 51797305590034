import ELECTION_DAY from "../enums/election-day.json";
import REGION from "../enums/region.json";

export const SCHEDULES = [
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1bR0B5Ixmpulf7CCZ-BuhQWTLt01_KjwiYP6TdHAdjHA/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1bR0B5Ixmpulf7CCZ-BuhQWTLt01_KjwiYP6TdHAdjHA/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1bR0B5Ixmpulf7CCZ-BuhQWTLt01_KjwiYP6TdHAdjHA/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.ALYTUS,
    file:
      "https://docs.google.com/spreadsheets/d/1bR0B5Ixmpulf7CCZ-BuhQWTLt01_KjwiYP6TdHAdjHA/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/12mKS646bIe5gd4LeEL7_kLZCLk5foPmrFzpNhZGhtD0/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/12mKS646bIe5gd4LeEL7_kLZCLk5foPmrFzpNhZGhtD0/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/12mKS646bIe5gd4LeEL7_kLZCLk5foPmrFzpNhZGhtD0/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.KAUNAS,
    file:
      "https://docs.google.com/spreadsheets/d/12mKS646bIe5gd4LeEL7_kLZCLk5foPmrFzpNhZGhtD0/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1caITshCiVrEeBO2FQyVGZbqjqehv8Gcsxit_vz51I4c/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1caITshCiVrEeBO2FQyVGZbqjqehv8Gcsxit_vz51I4c/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1caITshCiVrEeBO2FQyVGZbqjqehv8Gcsxit_vz51I4c/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.KLAIPEDA,
    file:
      "https://docs.google.com/spreadsheets/d/1caITshCiVrEeBO2FQyVGZbqjqehv8Gcsxit_vz51I4c/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1VkJkDjNGysPqvPkupF8Rllx36HsQoMqzeVCSA8lUGWw/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1VkJkDjNGysPqvPkupF8Rllx36HsQoMqzeVCSA8lUGWw/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1VkJkDjNGysPqvPkupF8Rllx36HsQoMqzeVCSA8lUGWw/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.MARIJAMPOLE,
    file:
      "https://docs.google.com/spreadsheets/d/1VkJkDjNGysPqvPkupF8Rllx36HsQoMqzeVCSA8lUGWw/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/10pC7gq7PhsBz-2musr9wvxNUTj-7vGLrBr4XPP0sK_8/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/10pC7gq7PhsBz-2musr9wvxNUTj-7vGLrBr4XPP0sK_8/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/10pC7gq7PhsBz-2musr9wvxNUTj-7vGLrBr4XPP0sK_8/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.PANEVEZYS,
    file:
      "https://docs.google.com/spreadsheets/d/10pC7gq7PhsBz-2musr9wvxNUTj-7vGLrBr4XPP0sK_8/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/14L2pKad28p72iuvSEUjXrc0KIo_46LOedbh5aPHJepY/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/14L2pKad28p72iuvSEUjXrc0KIo_46LOedbh5aPHJepY/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/14L2pKad28p72iuvSEUjXrc0KIo_46LOedbh5aPHJepY/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.SIAULIAI,
    file:
      "https://docs.google.com/spreadsheets/d/14L2pKad28p72iuvSEUjXrc0KIo_46LOedbh5aPHJepY/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/13N3Wsl2FHP6uZtWu0j0atw3RJeMTYMxu6-JtlEQgHdo/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/13N3Wsl2FHP6uZtWu0j0atw3RJeMTYMxu6-JtlEQgHdo/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/13N3Wsl2FHP6uZtWu0j0atw3RJeMTYMxu6-JtlEQgHdo/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.TAURAGE,
    file:
      "https://docs.google.com/spreadsheets/d/13N3Wsl2FHP6uZtWu0j0atw3RJeMTYMxu6-JtlEQgHdo/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1GM8_6c4XfpqCWPexYL79BcWnilmZca-aINPCL2-gGhQ/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1GM8_6c4XfpqCWPexYL79BcWnilmZca-aINPCL2-gGhQ/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1GM8_6c4XfpqCWPexYL79BcWnilmZca-aINPCL2-gGhQ/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.TELSIAI,
    file:
      "https://docs.google.com/spreadsheets/d/1GM8_6c4XfpqCWPexYL79BcWnilmZca-aINPCL2-gGhQ/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1OZKkJJI75h49wnLM_FYuKbye2AHM2nC6UGoA0huG0zE/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1OZKkJJI75h49wnLM_FYuKbye2AHM2nC6UGoA0huG0zE/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1OZKkJJI75h49wnLM_FYuKbye2AHM2nC6UGoA0huG0zE/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.UTENA,
    file:
      "https://docs.google.com/spreadsheets/d/1OZKkJJI75h49wnLM_FYuKbye2AHM2nC6UGoA0huG0zE/edit#gid=3"
  },
  {
    type: ELECTION_DAY.ADVANCE_MUNICIPALITY,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1M6LfrROm84MubBWdc6OodkWSURem2ZvpHi1Qu39I7Bk/edit#gid=0"
  },
  {
    type: ELECTION_DAY.ADVANCE_SPECIAL,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1M6LfrROm84MubBWdc6OodkWSURem2ZvpHi1Qu39I7Bk/edit#gid=1"
  },
  {
    type: ELECTION_DAY.ADVANCE_HOME,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1M6LfrROm84MubBWdc6OodkWSURem2ZvpHi1Qu39I7Bk/edit#gid=2"
  },
  {
    type: ELECTION_DAY.ELECTION_DAY,
    region: REGION.VILNIUS,
    file:
      "https://docs.google.com/spreadsheets/d/1M6LfrROm84MubBWdc6OodkWSURem2ZvpHi1Qu39I7Bk/edit#gid=3"
  }
];
