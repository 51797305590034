import React from "react";

import { useNavigateLocalized } from "../hooks/navigate-localized";
import SEO from "../components/seo";

import PreliminaryScheduleForm from "./schedules/preliminary-schedule-form";

const EditMySchedule = () => {
  const navigateToSchedules = useNavigateLocalized("/dashboard/schedules");

  return (
    <>
      <section>
        <SEO title="Mano grafikas" robots="noindex, nofollow" />

        <div className="bp-container">
          <h1>Mano grafikas</h1>

          <PreliminaryScheduleForm submitLabel="Išsaugoti" onComplete={navigateToSchedules} />
        </div>
      </section>
    </>
  );
};

export default EditMySchedule;
