import React, { useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { useTranslations } from "../../hooks/translations";
import Listbox from "../../components/listbox";

import styles from "./date-filter.module.scss";

const DateFilter = ({ allowedDates, value, onChange, className }) => {
  const t = useTranslations("clientRoutes.schedules");

  const enums = useTranslations("enums");

  const dateDropdownValues = useMemo(
    () =>
      allowedDates.map(electionDate => ({
        key: electionDate.toISOString(),
        value: `${enums.weekday[electionDate.getDay()]}, ${
          enums.month[electionDate.getMonth()]
        } ${electionDate.getDate()} ${t.d}`
      })),
    [allowedDates]
  );

  const selectedDate = useMemo(() => {
    if (value && allowedDates.some(allowedDate => allowedDate.valueOf() === value.valueOf())) {
      return value.toISOString();
    }

    return null;
  }, [value]);

  const setSelectedDate = useCallback(
    newValue => {
      onChange(newValue ? new Date(newValue) : newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if ((value && value.toISOString()) !== selectedDate) {
      setSelectedDate(null);
    }
  }, []);

  return (
    <div className={`${styles.filterContainer} ${className}`}>
      <span id="schedule-date-selector-label" className="bp-visually-hidden">
        {t.selectDate}
      </span>
      <Listbox
        id="schedule-date-selector"
        labelledBy="schedule-date-selector-label"
        options={dateDropdownValues}
        onChange={setSelectedDate}
        value={selectedDate}
        placeholder="Visos dienos"
        placeholderSelectable
      />
    </div>
  );
};

DateFilter.propTypes = {
  allowedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired).isRequired,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

DateFilter.defaultProps = {
  value: null,
  className: ""
};

export default DateFilter;
