import React from "react";

import DOCUMENTS from "../../content/documents.json";

import SEO from "../components/seo";
import LocalizedLink from "../components/localized-link";

const Documents = () => (
  <section>
    <SEO title="Dokumentai" robots="noindex, nofollow" />

    <h1 className="bp-heading">Dokumentai</h1>

    <div className="bp-container bp-left">
      <ul className="bp-styled-list">
        <li>
          <a
            href={DOCUMENTS.protest}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Protesto forma
          </a>{" "}
          - pildyti, jeigu rinkimų komisija nereaguoja į komentarus, nesutinka pašalinti pažeidimo,
          nepavyksta išspręsti problemos apylinkėje. Formoje išsamiai aprašyti, kokie pažeidimai
          buvo vykdomi, pateikti ją komisijos pirmininkui, o jos nuotrauką{" "}
          <LocalizedLink to="/report-violation" className="bp-link bp-accent">
            užregistruoti kaip pažeidimą per „Baltųjų pirštinių“ tinklapį
          </LocalizedLink>
          .
        </li>
        <li>
          <a
            href={DOCUMENTS.privacyPledge}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Pasižadėjimas dėl asmens duomenų apsaugos
          </a>{" "}
          - pildyti, jeigu rinkimų metu norite tikrinti rinkimų komisijų pildomus dokumentus
          (pavyzdžiui, prieš balsavimą namuose peržiūrėti P6 formas - t.y. rinkėjų prašymus balsuoti
          namuose). Formą atsispausdinti, įrašyti savo vardą ir pavardę, pasirašyti ir pateikti
          rinkimų komisijai.
        </li>
        {/* =====SECOND PHASE START===== */}
        <li>
          <a
            href={DOCUMENTS.fuelForm}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Kelionės išlaidų kompensavimo forma
          </a>{" "}
          - pildyti norint sulaukti kompensacijos už stebėjimo metu patirtas kelionės išlaidas -
          kurą, transporto bilietus. Užpildytų paraiškų lauksime iki lapkričio 3 d. Tuomet, sulaukę
          visų prašymų, žinosime tikslų besikreipiančiųjų skaičių ir paskaičiuosime, kokią išlaidų
          dalį galėsime kompensuoti.
        </li>
        {/* =====SECOND PHASE END===== */}
      </ul>
    </div>
  </section>
);

export default Documents;
