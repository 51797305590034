import USER_ROLE from "../../enums/user-role.json";
import YES_NO from "../../enums/yes-no.json";
import USER_STATUS from "../../enums/user-status.json";

import { isElectionOver } from "../utils/calendar";

import Volunteers from "./volunteers";
import VolunteerDetails from "./volunteer-details";
import VolunteerRoles from "./volunteer-roles";
import ViolationReports from "./violation-reports";
import ViolationReportDetails from "./violation-report-details";
import MyStatus from "./my-status";
import Schedules from "./schedules";
import EditMySchedule from "./edit-my-schedule";
import Questionnaires from "./questionnaires";
import PollingStationMap from "./polling-station-map";
import TrainingMaterial from "./training-material";
import Documents from "./documents";
// import FAQ from "./faq";
import Certificate from "./certificate";

const isActiveVolunteer = selfData =>
  selfData &&
  selfData.additionalInfoAvailable === YES_NO.YES &&
  (selfData.secondPhase === YES_NO.YES || selfData.secondPhaseOnly);

const shouldGetCertificate = selfData =>
  selfData &&
  selfData.adult === YES_NO.YES &&
  [USER_STATUS.ACTIVE, USER_STATUS.INACTIVE].includes(selfData.status) &&
  selfData.hasLicence === YES_NO.YES;

const ROUTE_CONFIG = [
  {
    path: "/volunteers",
    Component: Volunteers,
    roles: [USER_ROLE.ADMIN, USER_ROLE.COORDINATOR, USER_ROLE.NATIONAL_COORDINATOR],
    root: true,
    label: "volunteers"
  },
  {
    path: "/volunteers/:userId",
    Component: VolunteerDetails,
    roles: [USER_ROLE.ADMIN, USER_ROLE.COORDINATOR, USER_ROLE.NATIONAL_COORDINATOR]
  },
  {
    path: "/volunteers/:userId/roles",
    Component: VolunteerRoles,
    roles: [USER_ROLE.ADMIN]
  },
  {
    path: "/violation-reports",
    Component: ViolationReports,
    roles: [USER_ROLE.ADMIN, USER_ROLE.VIOLATION_ADMIN],
    root: true,
    label: "violation-reports"
  },
  {
    path: "/violation-reports/:reportId",
    Component: ViolationReportDetails,
    roles: [USER_ROLE.ADMIN, USER_ROLE.VIOLATION_ADMIN]
  }
];

if (isElectionOver()) {
  ROUTE_CONFIG.push({
    path: "/",
    Component: Certificate,
    root: true,
    label: "certificate",
    exact: true,
    customAccessCheck: shouldGetCertificate
  });
} else {
  ROUTE_CONFIG.push(
    {
      path: "/",
      Component: MyStatus,
      root: true,
      label: "self",
      exact: true
    },
    // =====SECOND PHASE START=====
    {
      path: "/schedules",
      Component: Schedules,
      customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
      root: true,
      label: "schedules"
    },
    {
      path: "/schedules/edit",
      Component: EditMySchedule,
      customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
      label: "edit"
    },
    {
      path: "/questionnaires",
      Component: Questionnaires,
      customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
      root: true,
      label: "questionnaires"
    },
    // =====SECOND PHASE END=====
    {
      path: "/polling-station-map",
      Component: PollingStationMap,
      customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
      root: true,
      label: "polling-station-map"
    },
    {
      path: "/training-material",
      Component: TrainingMaterial,
      customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
      root: true,
      label: "training-material"
    },
    {
      path: "/documents",
      Component: Documents,
      customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
      root: true,
      label: "documents"
    }
    // {
    //   path: "/faq",
    //   Component: FAQ,
    //   customAccessCheck: (selfData, claims) => isActiveVolunteer(selfData) || claims.ADMIN,
    //   root: true,
    //   label: "faq"
    // }
  );
}

export { ROUTE_CONFIG };
