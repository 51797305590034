import React from "react";
import SEO from "../components/seo";

import styles from "./polling-station-map.module.scss";

const PollingStationMap = () => (
  <>
    <SEO title="Rinkimų apylinkių žemėlapis" robots="noindex, nofollow" />

    <section className={styles.section}>
      <div className={`bp-full-screen ${styles.iframeWrapper}`}>
        <iframe
          title="Rinkimų apylinkių žemėlapis"
          src="https://www.google.com/maps/d/embed?mid=1N0AJlm2DrQQta4DDVDgXaHYx-_7sYXA"
          className={styles.iframe}
          allow="fullscreen geolocation 'src'"
          referrerPolicy="no-referrer"
          width="100%"
          height="100%"
        />
      </div>
    </section>
  </>
);

export default PollingStationMap;
