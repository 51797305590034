import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchViolationReports } from "../../state/violation-reports/violation-report-actions";
import { useTranslations } from "../../hooks/translations";

const translateReport = (report, enums) => {
  const { violationType, status, municipality, constituency } = report;

  return {
    ...report,
    violationType: enums.violationType[violationType],
    status: enums.reportStatus[status],
    municipality: enums.municipality[municipality],
    constituency: enums.constituency[constituency],
    raw: {
      status
    }
  };
};

const translateReports = (reports, enums) => reports.map(report => translateReport(report, enums));

const selector = state => state.violationReports;

export const useViolationReports = () => {
  const enums = useTranslations("enums");

  const dispatch = useDispatch();

  const { loading, reports, error } = useSelector(selector);

  useEffect(() => {
    if (!reports) {
      dispatch(fetchViolationReports());
    }
  }, []);

  const translatedReports = useMemo(
    () => (reports && reports.length > 0 ? translateReports(reports, enums) : reports),
    [reports]
  );

  return {
    loading,
    reports: translatedReports,
    error
  };
};

export const useViolationReport = reportId => {
  const { loading, reports, error } = useViolationReports();

  return {
    loading,
    report: reports ? reports.find(({ id }) => id === reportId) : null,
    error
  };
};
