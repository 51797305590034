import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";

import SSRContext from "../ssr-context";

import FormField from "./form-field";

const isDateTypeSupported = () => {
  const input = document.createElement("input");
  input.setAttribute("type", "date");

  const notADateValue = "not-a-date";
  input.setAttribute("value", notADateValue);

  return input.value !== notADateValue;
};

const isDelete = (previousValue, newValue) =>
  previousValue && previousValue.length === newValue.length + 1;

const formatDate = (newValue, previousValue) => {
  const deleting = isDelete(previousValue, newValue);

  let formattedDate = newValue.replace(/\D/g, "");

  const year = formattedDate.substr(0, 4);
  const month = formattedDate.substr(4, 2);
  const day = formattedDate.substr(6, 2);

  formattedDate = year;

  if (year.length === 4 && (month || !deleting)) {
    formattedDate = `${formattedDate}-${month}`;
  }

  if (month.length === 2 && (day || !deleting)) {
    formattedDate = `${formattedDate}-${day}`;
  }

  return formattedDate;
};

/* eslint-disable react/jsx-props-no-spreading */
const DateInput = ({ id, label, field, onChange, ...props }) => {
  const isSSR = useContext(SSRContext);

  const formatValue = useMemo(
    () => (!isSSR && isDateTypeSupported() ? value => value : formatDate),
    [isSSR]
  );

  return (
    <FormField id={id} label={label} field={field}>
      <input
        type="date"
        placeholder="yyyy-mm-dd"
        id={id}
        name={id}
        onChange={e => onChange(id, field, formatValue(e.target.value, field.value))}
        value={field.value}
        className={field.error ? "bp-error" : null}
        {...props}
      />
    </FormField>
  );
};
/* eslint-enable react/jsx-props-no-spreading */

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  field: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateInput;
