import React, { useMemo } from "react";
import PropTypes from "prop-types";

import Listbox from "./listbox";

const TableDropdownFilter = ({ id, label, options, value, onChange, multiple }) => (
  <div>
    <span id={`${id}-filter-label`} className="bp-label">
      {label}
    </span>
    <Listbox
      id={`${id}-table-filter`}
      labelledBy={`${id}-filter-label`}
      options={useMemo(
        () =>
          options.map(key =>
            typeof key === "string"
              ? {
                  key,
                  value: key
                }
              : key
          ),
        [options]
      )}
      onChange={onChange}
      value={value}
      placeholder="-"
      multiple={multiple}
      placeholderSelectable
    />
  </div>
);

TableDropdownFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ]).isRequired
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool
};

TableDropdownFilter.defaultProps = {
  value: undefined,
  multiple: false
};

export default TableDropdownFilter;
