import React from "react";
import PropTypes from "prop-types";

import styles from "./file-list.module.scss";

const FileLinkList = ({ items }) => (
  <ul className={styles.list}>
    {items.map(({ key, file, label }) => (
      <li key={key} className={styles.listItem}>
        <a
          href={file}
          target="_blank"
          rel="noreferrer noopener nofollow"
          className={styles.fileLink}
        >
          {label}
        </a>
      </li>
    ))}
  </ul>
);

FileLinkList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      file: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    }).isRequired
  )
};

FileLinkList.defaultProps = {
  items: []
};

export default FileLinkList;
