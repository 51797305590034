import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useSelf } from "../hooks/self";
import { useNavigateLocalized } from "../hooks/navigate-localized";

const ProtectedRoute = ({ component: Component, roles, customAccessCheck, ...props }) => {
  const navigateToDashboard = useNavigateLocalized("/dashboard/");
  const navigateToLogin = useNavigateLocalized("/login");

  const { user, claims, userData, initializing } = useSelf();

  const isLoggedIn = useMemo(() => !!user, [user]);

  const hasAccess = useMemo(() => {
    if (customAccessCheck) {
      return customAccessCheck(userData, claims);
    }

    if (roles && roles.length > 0) {
      return roles.some(role => claims[role]);
    }

    return isLoggedIn;
  }, [isLoggedIn, claims, userData, roles, customAccessCheck]);

  useEffect(() => {
    if (!initializing && !hasAccess) {
      if (isLoggedIn) {
        navigateToDashboard();
      } else {
        navigateToLogin();
      }
    }
  }, [initializing, isLoggedIn, hasAccess]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return !initializing && hasAccess ? <Component {...props} /> : null;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired),
  customAccessCheck: PropTypes.func
};

ProtectedRoute.defaultProps = {
  roles: [],
  customAccessCheck: null
};

export default ProtectedRoute;
