import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useCoordinators } from "../hooks/self";

import styles from "./coordinators.module.scss";

const Coordinator = ({ coordinator: { name, email, phone }, displayEmail }) => (
  <span className={styles.coordinator}>
    {name},{" "}
    {displayEmail ? (
      <>
        <a href={`mailto:${email}`} className="bp-link">
          {email}
        </a>
        ,{" "}
      </>
    ) : null}
    <a href={`tel:${phone}`} className="bp-link">
      {phone}
    </a>
  </span>
);

Coordinator.propTypes = {
  displayEmail: PropTypes.bool.isRequired,
  coordinator: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  }).isRequired
};

const Coordinators = ({ displayEmail }) => {
  const { coordinators, loading } = useCoordinators();

  const coordinatorList = useMemo(
    () =>
      coordinators && coordinators.length > 0 ? (
        coordinators.map((coordinator, index) => (
          <span key={coordinator.email}>
            <Coordinator coordinator={coordinator} displayEmail={displayEmail} />
            {index < coordinators.length - 1 ? " / " : ""}
          </span>
        ))
      ) : (
        <span>
          jei nežinai, kas Tavo koordinatorius, susisiek su mumis el. paštu{" "}
          <a href="mailto:savanoriai@baltosiospirstines.lt" className="bp-link">
            savanoriai@baltosiospirstines.lt
          </a>
        </span>
      ),
    [coordinators]
  );

  return loading ? <>...</> : coordinatorList;
};

Coordinators.propTypes = {
  displayEmail: PropTypes.bool
};

Coordinators.defaultProps = {
  displayEmail: false
};

export default Coordinators;
