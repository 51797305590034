import React, { useMemo } from "react";

import { QUESTIONNAIRES } from "../../content/questionnaires";

import { useTranslations } from "../hooks/translations";
import { useSelf } from "../hooks/self";
import SEO from "../components/seo";
import FileLinkList from "../components/file-link-list";

const Questionnaires = () => {
  const t = useTranslations("clientRoutes.questionnaires");

  const { userData } = useSelf();

  const questionnaireItems = useMemo(
    () =>
      Object.entries(QUESTIONNAIRES).map(([key, file]) => ({
        key,
        file: file
          .replace("<<NAME>>", encodeURIComponent(`${userData.name} ${userData.surname}`))
          .replace("<<PHONE>>", encodeURIComponent(userData.phone)),
        label: t[key]
      })),
    [userData]
  );

  return (
    <section>
      <SEO title="Stebėtojo klausimynai" robots="noindex, nofollow" />

      <h1>Stebėtojo klausimynai</h1>

      <div className="bp-container bp-left">
        <p>
          <b>Stebėtojo klausimynas</b> - tai pagalbos įrankis, padėsiantis Tau atkreipti dėmesį į
          svarbiausius aspektus stebint balsavimą. Nacionaliniam biurui šis įrankis yra pagrindinis
          stebėjimo duomenų surinkimo šaltinis.
        </p>

        <p>
          <strong>
            Svarbu! Po apsilankymo kiekvienoje balsavimo vietoje, būtina pateikti užpildytą
            atitinkamą stebėtojo klausimyną.
          </strong>{" "}
          Klausimynai pildomi baigus stebėjimą savivaldybėje / specialiajame punkte / apylinkėje /
          namuose vienoje apylinkėje (<em>ne pavieniuose namuose</em>
          ).
        </p>

        <FileLinkList items={questionnaireItems} />
      </div>
    </section>
  );
};

export default Questionnaires;
