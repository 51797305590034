import React from "react";

import { useSelf } from "../../hooks/self";
import { useNavigateLocalized } from "../../hooks/navigate-localized";
import LocalizedLink from "../../components/localized-link";
import Coordinators from "../../components/coordinators";

import PreliminaryScheduleForm from "../schedules/preliminary-schedule-form";

import styles from "./ready.module.scss";

const Ready = () => {
  const { userData } = useSelf();

  const navigateToSchedules = useNavigateLocalized("/dashboard/schedules");

  return (
    <>
      {userData.scheduleRevised ? (
        <>
          <h1 className="bp-heading">Sveikiname tapus „Baltųjų pirštinių“ stebėtoju!</h1>

          <div className="bp-container">
            <p className="bp-paragraph">
              Tu pateikei reikalingus duomenis, išėjai mokymus ir atsiėmei stebėtojo pažymėjimą,
              kuris Tau suteikia teisę stebėti rinkimus visose Lietuvos Respublikos savivaldybėse!
            </p>

            <ul className="bp-styled-list">
              <li className="bp-paragraph">
                Prieš keliaudamas stebėti rinkimų būtinai pasižymėk{" "}
                <LocalizedLink to="/dashboard/schedules" className={`bp-link ${styles.textLink}`}>
                  stebėjimo grafike
                </LocalizedLink>
                , o stebėjimo metu nepamiršk pildyti{" "}
                <LocalizedLink
                  to="/dashboard/questionnaires"
                  className={`bp-link ${styles.textLink}`}
                >
                  stebėtojo klausimynų
                </LocalizedLink>
                .
              </li>

              <li className="bp-paragraph">
                Primename, kad užregistruoti pažeidimą galima ne tik klausimynuose, bet ir{" "}
                <LocalizedLink to="/report-violation" className={`bp-link ${styles.textLink}`}>
                  mūsų sistemoje
                </LocalizedLink>
                .
              </li>

              <li className="bp-paragraph">
                Kilus klausimams dėl balsavimo stebėjimo visada gali peržiūrėti{" "}
                <LocalizedLink
                  to="/dashboard/training-material"
                  className={`bp-link ${styles.textLink}`}
                >
                  mokomąją medžiagą
                </LocalizedLink>
                , na o jei iškils problemų, susisiek su savo koordinatoriumi (
                <Coordinators />
                ).
              </li>
            </ul>

            <p className="bp-paragraph">Ačiū Tau, sėkmės ir gero bei saugaus stebėjimo!</p>
          </div>
        </>
      ) : (
        <>
          <h1 className="bp-heading">Pirmyn į rinkimus!</h1>

          <div className="bp-container bp-left">
            <p className="bp-paragraph">
              Sveikiname - nuo šios akimirkos Tu turi teisę stebėti rinkimus visoje Lietuvos
              Respublikos teritorijoje! Pažymėk savo apytikrius rinkimų savaitės planus žemiau
              esančioje formoje ir sistema Tau pateiks stebėjimo grafikus.
            </p>

            <PreliminaryScheduleForm onComplete={navigateToSchedules} />
          </div>
        </>
      )}
    </>
  );
};

export default Ready;
