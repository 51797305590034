import { useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useSelf } from "../../hooks/self";
import { fetchUserRoles } from "../../state/user-roles/user-roles-actions";

export const useUserRoles = userId => {
  const { claims } = useSelf();

  const dispatch = useDispatch();

  const selector = useCallback(state => state.userRoles[userId] || {}, [userId]);

  const { loading, roles } = useSelector(selector);

  useEffect(() => {
    if (!roles && claims.ADMIN) {
      dispatch(fetchUserRoles(userId));
    }
  }, []);

  return {
    loading,
    ...roles
  };
};
