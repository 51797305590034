import React, { useState, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "@reach/router";

import USER_STATUS from "../../enums/user-status.json";
import YES_NO from "../../enums/yes-no.json";

import { updateUser } from "../api/volunteers";
import { useTranslations } from "../hooks/translations";
import { useLinkTranslation } from "../hooks/link-translation";
import { useSelf } from "../hooks/self";
import { updateUser as updateUserAction } from "../state/users/user-actions";
import SpinnerOverlay from "../components/spinner-overlay";
import SEO from "../components/seo";
import Button from "../components/button";
import ConfirmationModal from "../components/confirmation-modal";
import ButtonLink from "../components/button-link";

import { useUser } from "./hooks/users";
import UserComments from "./components/user-comments";

import styles from "./volunteer-details.module.scss";

const STATUS_CLASSES = {
  [USER_STATUS.UNVERIFIED]: styles.unverified,
  [USER_STATUS.VERIFIED]: styles.verified,
  [USER_STATUS.DISMISSED]: styles.dismissed,
  [USER_STATUS.INACTIVE]: styles.inactive,
  [USER_STATUS.ACTIVE]: styles.active,
  [USER_STATUS.BLOCKED]: styles.blocked
};

const UPDATE_OPERATION = {
  STATUS: "STATUS",
  TRAINING: "TRAINING",
  LICENCE: "LICENCE"
};

const VolunteerDetails = () => {
  const t = useTranslations("clientRoutes.volunteerDetails");

  const rolesPath = useLinkTranslation("roles");

  const { userId } = useParams();

  const { claims } = useSelf();

  const { loading, user } = useUser(userId);

  const [updating, setUpdating] = useState(false);

  const [confirmation, setConfirmation] = useState(null);

  const [personalCodeVisible, setPersonalCodeVisible] = useState(false);

  const dispatch = useDispatch();

  const updateUserData = useCallback(
    (updateType, data) => {
      setUpdating(updateType);
      updateUser(userId, data)
        .then(() => {
          dispatch(updateUserAction(userId, data));
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [setUpdating, dispatch]
  );

  const onConfirmationClose = useCallback(
    confirmed => {
      setConfirmation(null);

      if (confirmed) {
        confirmation.callback();
      }
    },
    [confirmation, setConfirmation]
  );

  const verifyUser = useCallback(
    () => updateUserData(UPDATE_OPERATION.STATUS, { status: USER_STATUS.VERIFIED }),
    [updateUserData]
  );

  const dismissUser = useCallback(
    () => updateUserData(UPDATE_OPERATION.STATUS, { status: USER_STATUS.DISMISSED }),
    [updateUserData]
  );

  const blockUser = useCallback(
    () => updateUserData(UPDATE_OPERATION.STATUS, { status: USER_STATUS.BLOCKED }),
    [updateUserData]
  );

  const activateUser = useCallback(
    () =>
      updateUserData(UPDATE_OPERATION.STATUS, {
        status: user.hasAccount ? USER_STATUS.ACTIVE : USER_STATUS.VERIFIED
      }),
    [user, updateUserData]
  );

  const deactivateUser = useCallback(
    () => updateUserData(UPDATE_OPERATION.STATUS, { status: USER_STATUS.INACTIVE }),
    [updateUserData]
  );

  const markTrainingCompleted = useCallback(
    () => updateUserData(UPDATE_OPERATION.TRAINING, { trainingCompleted: YES_NO.YES }),
    [updateUserData]
  );

  const markHasLicence = useCallback(
    () => updateUserData(UPDATE_OPERATION.LICENCE, { hasLicence: YES_NO.YES }),
    [updateUserData]
  );

  const attemptTrainingStatusUpdate = useCallback(() => {
    setConfirmation({
      ...t.trainingStatusUpdateConfirmation,
      callback: markTrainingCompleted
    });
  }, [setConfirmation, markTrainingCompleted]);

  const attemptLicenceStatusUpdate = useCallback(() => {
    setConfirmation({
      ...t.licenceStatusUpdateConfirmation,
      callback: markHasLicence
    });
  }, [setConfirmation, markHasLicence]);

  const STATUS_ACTIONS = useMemo(
    () => ({
      verify: {
        label: t.verify,
        className: "bp-standard",
        action: () => {
          setConfirmation({
            ...t.verificationConfirmation,
            callback: verifyUser
          });
        }
      },
      dismiss: {
        label: t.dismiss,
        className: "bp-inverse",
        action: dismissUser
      },
      deactivate: {
        label: t.deactivate,
        className: "bp-standard",
        action: deactivateUser
      },
      activate: {
        label: t.activate,
        className: "bp-standard",
        action: activateUser
      },
      block: {
        label: t.block,
        className: "bp-inverse",
        action: blockUser
      }
    }),
    [setConfirmation, activateUser, deactivateUser, verifyUser]
  );

  const STATUS_ACTION_BUTTONS = useMemo(
    () => ({
      [USER_STATUS.UNVERIFIED]: [STATUS_ACTIONS.verify, STATUS_ACTIONS.dismiss],
      [USER_STATUS.VERIFIED]: [STATUS_ACTIONS.deactivate],
      [USER_STATUS.DISMISSED]: [STATUS_ACTIONS.verify, STATUS_ACTIONS.block],
      [USER_STATUS.INACTIVE]: [STATUS_ACTIONS.activate, STATUS_ACTIONS.block],
      [USER_STATUS.ACTIVE]: [STATUS_ACTIONS.deactivate],
      [USER_STATUS.BLOCKED]: []
    }),
    [STATUS_ACTIONS]
  );

  const activityList = useMemo(() => {
    if (user && user.activities && user.activities.length > 0) {
      const allActivities = user.activities.filter(item => item !== "OTHER");

      if (allActivities.length !== user.activities.length) {
        allActivities.push(user.otherHelp);
      }

      return allActivities;
    }

    return [];
  }, [user]);

  const seoTitle = useMemo(() => (user ? `${user.name} ${user.surname} - ${t.title}` : t.title), [
    user
  ]);

  const isTrainingCompleted = useMemo(() => user && user.raw.trainingCompleted === YES_NO.YES, [
    user
  ]);

  const isLicenceCollected = useMemo(() => user && user.raw.hasLicence === YES_NO.YES, [user]);

  const isActive = useMemo(() => user && user.raw.status === USER_STATUS.ACTIVE, [user]);

  const hasAdminAccess = useMemo(() => claims.ADMIN, [claims]);

  const hasCoordinatorAccess = useMemo(() => claims.COORDINATOR || claims.NATIONAL_COORDINATOR, [
    claims
  ]);

  const isTrainingAdmin = useMemo(() => claims.NATIONAL_COORDINATOR && claims.TRAINING_ADMIN, [
    claims
  ]);

  const displaySpinner = useMemo(() => loading || !user, [loading, user]);

  const allowStatusChange = useMemo(() => hasAdminAccess, [hasAdminAccess]);

  const allowRoleManagement = useMemo(() => hasAdminAccess && isActive, [hasAdminAccess, isActive]);

  const displayTrainingAndLicenceInfo = useMemo(
    () => user && user.hasAccount && user.raw.adult === YES_NO.YES,
    [user]
  );

  const allowTrainingUpdate = useMemo(
    () => !isTrainingCompleted && isActive && (hasAdminAccess || isTrainingAdmin),
    [isTrainingCompleted, hasAdminAccess, isTrainingAdmin, isActive]
  );

  const allowLicenceUpdate = useMemo(
    () =>
      isTrainingCompleted &&
      !isLicenceCollected &&
      (hasAdminAccess || hasCoordinatorAccess) &&
      isActive,
    [isTrainingCompleted, isLicenceCollected, hasAdminAccess, hasCoordinatorAccess, isActive]
  );

  const isAdditionalInfoAvailable = useMemo(
    () => user && user.raw.additionalInfoAvailable === YES_NO.YES,
    [user]
  );

  const hasPersonalCodeAccess = useMemo(() => isAdditionalInfoAvailable && hasAdminAccess, [
    isAdditionalInfoAvailable,
    hasAdminAccess
  ]);

  return (
    <>
      <SEO title={seoTitle} robots="noindex, nofollow" />

      <section>
        <h1>{t.heading}</h1>

        {displaySpinner ? (
          <SpinnerOverlay />
        ) : (
          <div className="bp-container bp-left">
            <div className={`bp-card ${styles.card}`}>
              <div className={`bp-between ${styles.header}`}>
                <div className="bp-between">
                  <h2>
                    {user.name} {user.surname}
                  </h2>

                  <span className={`${styles.userStatus} ${STATUS_CLASSES[user.raw.status]}`}>
                    {user.status}
                  </span>
                </div>

                {allowStatusChange ? (
                  <div>
                    {STATUS_ACTION_BUTTONS[user.raw.status].map(({ label, className, action }) => (
                      <Button
                        key={label}
                        className={`${styles.statusButton} ${className}`}
                        onClick={action}
                        busy={updating === UPDATE_OPERATION.STATUS}
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                ) : null}
              </div>

              {allowRoleManagement ? (
                <>
                  <h3>{t.roles}</h3>
                  <p className={styles.statusParagraph}>
                    <ButtonLink to={rolesPath} className="bp-standard">
                      {t.manageRoles}
                    </ButtonLink>
                  </p>
                </>
              ) : null}

              {displayTrainingAndLicenceInfo ? (
                <>
                  <h3>{t.training}</h3>
                  <div className={`bp-between ${styles.row}`}>
                    <p
                      className={`${styles.statusParagraph} ${
                        isTrainingCompleted ? styles.complete : ""
                      }`}
                    >
                      {user.trainingCompleted}
                    </p>
                    {allowTrainingUpdate ? (
                      <p>
                        <Button
                          className="bp-standard"
                          onClick={() => attemptTrainingStatusUpdate()}
                          busy={updating === UPDATE_OPERATION.TRAINING}
                        >
                          {t.markTrainingCompleted}
                        </Button>
                      </p>
                    ) : null}
                  </div>

                  <h3>{t.licence}</h3>
                  <div className={styles.row}>
                    <p
                      className={`${styles.statusParagraph} ${
                        isLicenceCollected ? styles.complete : ""
                      }`}
                    >
                      {user.hasLicence}
                    </p>
                    {allowLicenceUpdate ? (
                      <p>
                        <Button
                          className="bp-standard"
                          onClick={() => attemptLicenceStatusUpdate()}
                          busy={updating === UPDATE_OPERATION.LICENCE}
                        >
                          {t.markHasLicence}
                        </Button>
                      </p>
                    ) : null}
                    <p className={styles.fullWidth}>
                      <span id="label-licence-method" className="bp-large-label">
                        {t.licenceCollectionMethod}
                      </span>
                      <span aria-labelledby="label-licence-method">
                        {user.licenceCollectionMethod || "-"}
                      </span>
                    </p>
                  </div>
                </>
              ) : null}

              <h3>{t.contactsAndPersonalInfo}</h3>
              <div className={styles.row}>
                <p>
                  <span id="label-phone" className="bp-large-label">
                    {t.phone}
                  </span>
                  <a href={`tel:${user.phone}`} className="bp-link" aria-labelledby="label-phone">
                    {user.phone}
                  </a>
                </p>
                <p>
                  <span id="label-email" className="bp-large-label">
                    {t.email}
                  </span>
                  <a
                    href={`mailto:${user.email}`}
                    className="bp-link"
                    aria-labelledby="label-email"
                  >
                    {user.email}
                  </a>
                </p>
                <p>
                  <span id="label-municipality" className="bp-large-label">
                    {t.municipality}
                  </span>
                  <span aria-labelledby="label-municipality">{user.municipality}</span>
                </p>
                <p>
                  <span id="label-birth-date" className="bp-large-label">
                    {t.birthDate}
                  </span>
                  <span aria-labelledby="label-birth-date">{user.birthDateString}</span>
                </p>
                <p>
                  <span id="label-registration-date" className="bp-large-label">
                    {t.registrationDate}
                  </span>
                  <span aria-labelledby="label-registration-date">
                    {user.createdAtString || "-"}
                  </span>
                </p>
                <p>
                  <span id="label-updated-at" className="bp-large-label">
                    {t.updateDate}
                  </span>
                  <span aria-labelledby="label-updated-at">{user.updatedAtString || "-"}</span>
                </p>
                {hasPersonalCodeAccess ? (
                  <p>
                    <span id="label-personal-code" className="bp-large-label">
                      {t.personalCode}
                    </span>
                    <span aria-labelledby="label-personal-code">
                      {/* eslint-disable no-nested-ternary */}
                      {user.personalCode ? (
                        personalCodeVisible ? (
                          user.personalCode
                        ) : (
                          <Button
                            onClick={() => setPersonalCodeVisible(true)}
                            className={`bp-link ${styles.displayPersonalCode}`}
                          >
                            {t.display}
                          </Button>
                        )
                      ) : (
                        "-"
                      )}
                      {/* eslint-enable no-nested-ternary */}
                    </span>
                  </p>
                ) : null}
                <p>
                  <span id="label-organization" className="bp-large-label">
                    {t.organization}
                  </span>
                  <span aria-labelledby="label-organization">{user.organization || "-"}</span>
                </p>
              </div>

              {isAdditionalInfoAvailable ? (
                <>
                  <h3>{t.details}</h3>
                  <div className={styles.row}>
                    <p>
                      <span id="label-experience" className="bp-large-label">
                        {t.experience}
                      </span>
                      <span aria-labelledby="label-experience">
                        {user.experience && user.experience.length > 0
                          ? user.experience.map(item => (
                              <span key={item} className={styles.entry}>
                                {item}
                              </span>
                            ))
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span id="label-has-car" className="bp-large-label">
                        {t.car}
                      </span>
                      <span aria-labelledby="label-has-car">{user.car || "-"}</span>
                    </p>
                    <p>
                      <span id="label-activities" className="bp-large-label">
                        {t.activities}
                      </span>
                      <span aria-labelledby="label-activities">
                        {activityList.length > 0
                          ? activityList.map(item => (
                              <span key={item} className={styles.entry}>
                                {item}
                              </span>
                            ))
                          : "-"}
                      </span>
                    </p>
                  </div>

                  <h3>{t.schedule}</h3>
                  <div className={styles.row}>
                    <p>
                      <span id="label-availability" className="bp-large-label">
                        {t.availability}
                      </span>
                      <span aria-labelledby="label-availability">
                        {user.availability && user.availability.length > 0
                          ? user.availability.map(entry => (
                              <span key={entry.dateString} className={styles.entry}>
                                <span className="bp-between">
                                  {entry.dateString} {entry.fromTimeString || "00:00"} -{" "}
                                  {entry.toTimeString || "24:00"}
                                </span>
                              </span>
                            ))
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span id="label-municipalities" className="bp-large-label">
                        {t.municipalities}
                      </span>
                      <span aria-labelledby="label-municipalities">
                        {user.municipalities && user.municipalities.length > 0
                          ? user.municipalities.map(item => (
                              <span key={item} className={styles.entry}>
                                {item}
                              </span>
                            ))
                          : "-"}
                      </span>
                    </p>
                    <p>
                      <span id="label-vote-count" className="bp-large-label">
                        {t.voteCount}
                      </span>
                      <span aria-labelledby="label-vote-count">{user.scheduleVoteCount}</span>
                    </p>
                  </div>
                </>
              ) : null}

              <UserComments userId={userId} className={styles.comments} />
            </div>
          </div>
        )}
      </section>

      {confirmation ? (
        <ConfirmationModal
          heading={confirmation.heading}
          description={confirmation.description}
          yes={t.yes}
          no={t.no}
          onClose={onConfirmationClose}
        />
      ) : null}
    </>
  );
};

export default VolunteerDetails;
