import React, { useMemo } from "react";

import TRAINING from "../../../content/training.json";

import { useSelf } from "../../hooks/self";

import styles from "./training.module.scss";

const Training = () => {
  const { userData } = useSelf();

  const testUrl = useMemo(
    () =>
      TRAINING.test
        .replace("<<NAME>>", encodeURIComponent(`${userData.name} ${userData.surname}`))
        .replace("<<EMAIL>>", encodeURIComponent(userData.email)),
    [userData]
  );

  return (
    <>
      <h1 className="bp-heading">Mokymai ir žinių patikrinimas</h1>

      <h2 className={styles.heading}>
        Kiekvienas profesionalus stebėtojas turi tinkamai pasiruošti rinkimams. Mūsų siūlomas
        mokymosi planas:
      </h2>

      <ol className={styles.steps}>
        <li>
          Mokykis žiūrėdamas rinkimų stebėtojų mokymų įrašą sau patogiu metu per šią nuorodą:{" "}
          <a
            href={TRAINING.liveCourse}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            {TRAINING.liveCourse}
          </a>
          . Skaidres rasi čia:{" "}
          <a
            href={TRAINING.liveCourseSlides}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            {TRAINING.liveCourseSlides}
          </a>
          .
        </li>
        <li>
          Susipažink ir naudokis{" "}
          <a
            href={TRAINING.observerGuidelines}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Nepriklausomo rinkimų stebėtojo vadovu
          </a>{" "}
          - jame praktiniai patarimai, atsakingų asmenų kontaktai ir daug kitos naudingos
          informacijos mūsų stebėtojams.
        </li>
        <li>
          Savarankiškai susipažink ir įsigilink į rinkimų teisę - skaityk{" "}
          <a
            href={TRAINING.organizerHandbook}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            rinkimų organizatoriaus („baltąją“) knygą
          </a>{" "}
          bei{" "}
          <a
            href={TRAINING.electionLaw}
            target="_blank"
            rel="noreferrer noopener"
            className="bp-link bp-accent"
          >
            Lietuvos Respublikos rinkimų kodeksą
          </a>
          .
        </li>
      </ol>

      <h2 className={styles.heading}>
        Prieš tampant stebėtoju būtina pasitikrinti savo žinias apie rinkimus!
      </h2>

      <p className="bp-paragraph">
        Siekiame, kad rinkimai būtų stebimi maksimaliai kokybiškai - prisidėk prie profesionalumo
        patikrindamas savo žinias. <b>Visi asmenys</b>, ketinantys būti nepriklausomais rinkimų
        stebėtojais, privalo užpildyti žinių patikrinimo testą.
      </p>

      <p className="bp-paragraph">
        <a href={testUrl} target="_blank" rel="noreferrer noopener" className="bp-link bp-accent">
          Pildyti rinkimų stebėtojo testą.
        </a>
      </p>
    </>
  );
};

export default Training;
